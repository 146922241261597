import React from 'react';
import { Outlet } from "react-router-dom";

const Root = () => (
    <>
        <Outlet />
    </>
);

export default Root;
