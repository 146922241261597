import httpClient from "../utils/httpClient";

export const getCounsellingSeatList = () => httpClient.get('counselling-seat');

export const createCounsellingSeat = (data) => httpClient.post('counselling-seat', JSON.stringify(data));

export const updateCounsellingSeat = (data) => httpClient.put(`counselling-seat/${data.id}`, JSON.stringify(data));

export const deleteCounsellingSeat = (id) => httpClient.delete(`counselling-seat/${id}`);

