import React, { useEffect, useMemo, useState } from "react";

import { getUser } from "../../services/user";
import DataTable from "react-data-table-component";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { deleteStudent } from "../../services/student";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <Button
    className="move-btn move-btn-re"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </Button>
);

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Search Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

export default function StudentList() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const columns = useMemo(
    () => [
      {
        name: "#",
        sortable: true,
        width: "80px",
        cell: (row) => <CustomTitle title={row.ID} />,
        selector: (row) => row.ID,
      },
      {
        name: "Name",
        sortable: true,
        width: "160px",
        cell: (row) => <CustomTitle title={row.Name} />,
        selector: (row) => row.Name,
      },
      {
        name: "Mobile",
        sortable: true,
        width: "120px",
        cell: (row) => <CustomTitle title={row.Mobile} />,
        selector: (row) => row.Mobile,
      },
      {
        name: "Email",
        sortable: true,
        width: "300px",
        selector: (row) => row.Email,
      },
      {
        name: "Source",
        sortable: true,
        width: "120px",
        selector: (row) => row.Source,
      },
      {
        name: "Gender",
        sortable: true,
        width: "120px",
        selector: (row) => row.Gender,
      },
      {
        name: "DOB",
        sortable: true,
        width: "120px",
        selector: (row) => row.DOB,
      },
      {
        name: "Address",
        sortable: true,
        width: "120px",
        selector: (row) => row.Address,
      },
      {
        name: "PinCode",
        sortable: true,
        width: "120px",
        selector: (row) => row.Pincode,
      },
      {
        name: "City",
        sortable: true,
        width: "120px",
        selector: (row) => row.City,
      },
      {
        name: "State",
        sortable: true,
        width: "120px",
        selector: (row) => row.State,
      },
      {
        name: "NEET Category",
        sortable: true,
        width: "150px",
        selector: (row) => row.NeetCategory,
      },
      {
        name: "State Category",
        sortable: true,
        width: "150px",
        selector: (row) => row.StateCategory,
      },
      {
        name: "10th Passing State",
        sortable: true,
        width: "150px",
        selector: (row) => row.State10th,
      },
      {
        name: "12th Passing State",
        sortable: true,
        width: "150px",
        selector: (row) => row.State12th,
      },
      {
        name: "PH Candidate",
        sortable: true,
        width: "150px",
        selector: (row) => row.PhCandidate,
      },
      {
        name: "Area",
        sortable: true,
        width: "120px",
        selector: (row) => row.Area,
      },
      {
        name: "Armed Force",
        sortable: true,
        width: "120px",
        selector: (row) => row.ArmedForce,
      },
      {
        name: "Quota",
        sortable: true,
        width: "120px",
        selector: (row) => row.Quota,
      },
      {
        name: "Minority",
        sortable: true,
        width: "120px",
        selector: (row) => row.Minority,
      },
      {
        name: "Registration Date",
        sortable: true,
        selector: (row) => row.StudentCreatedAt,
      },
      {
        name: "Last Login",
        sortable: true,
        width: "250px",
        selector: (row) => row.StudentUpdatedAt,
      },
      {
        name: "Delete",
        selector: (row) => (
          <Link
            type="button"
            onClick={() => handleRemove(row.deleteId)}
            state={row}
            className="btn btn-danger"
          >
            <i className="bi bi-trash"></i>
          </Link>
        ),
      },
    ],
    []
  );

   const [filterText, setFilterText] = React.useState("");
   const [resetPaginationToggle, setResetPaginationToggle] =
     React.useState(false);
   const filteredItems = data.filter(
     (item) =>
       item.Name &&
       item.Name.toLowerCase().includes(filterText.toLowerCase())
   );

   const subHeaderComponentMemo = React.useMemo(() => {
     const handleClear = () => {
       if (filterText) {
         setResetPaginationToggle(!resetPaginationToggle);
         setFilterText("");
       }
     };

     return (
       <FilterComponent
         onFilter={(e) => setFilterText(e.target.value)}
         onClear={handleClear}
         filterText={filterText}
       />
     );
   }, [filterText, resetPaginationToggle]);

  const actionsMemo = useMemo(
    () => (
      <React.Fragment>
        <Export onExport={() => downloadCSV(data)} />
      </React.Fragment>
    ),
    [data]
  );

  const fetchData = () => {
    getUser().then((result) => {
      if (result.data.error === false) {
        const setValue = result.data.result.filter((item) => item.userRoleId === 1);
        setData(
          setValue.map((item, index) => ({
            ID: index + 1,
            deleteId: item.student?.id,
            Name: item.name,
            Mobile: item.mobile,
            Email: item.email,
            Source: item.source,
            Gender: item.student?.gender,
            DOB: item.student?.dob ? item.student?.dob.split("T")[0] : "N/A",
            Address: item.student?.address?.address
              ? item.student?.address?.address
              : "N/A",
            Pincode: item.student?.address?.pincode,
            City: item.student?.address?.city?.name,
            State: item.student?.address?.state?.name,
            NeetCategory: item.student?.neet_category?.category,
            StateCategory: item.student?.state_category?.category,
            State10th: item.student?.state10th?.name,
            State12th: item.student?.state12th?.name,
            PhCandidate: item.student
              ? item.student?.isPhCandidate === false
                ? "NO"
                : "YES"
              : "",
            Area: item.student?.area?.area,
            ArmedForce: item.student?.armed_force?.armedForce,
            Quota: item.student?.quota?.quota,
            Minority: item.student?.minority?.minority,
            StudentCreatedAt: moment(item.createdAt).format("L LTS"),
            StudentUpdatedAt: moment(item.updatedAt).format("L LTS"),
          }))
        );
      }
    })
    .catch((err) => errorMessage())
      .finally(() => setPending(false));
  };
  useEffect(() => fetchData(), []);

   const handleRemove = (id) => {
     deleteStudent(id).then((result) => {
       if (result.data.error === false) {
         successMessage();
         fetchData();
       } else {
         errorMessage();
       }
     });
   };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Student</h5>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  progressPending={pending}
                  actions={actionsMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}