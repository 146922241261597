import httpClient from "../utils/httpClient";

export const getNeetCategoryList = () => httpClient.get("neet-category");

export const createNeetCategory = (data) =>
  httpClient.post("neet-category", JSON.stringify(data));

export const updateNeetCategory = (data) =>
  httpClient.put(`neet-category/${data.id}`, JSON.stringify(data));

export const deleteNeetCategory = (id) =>
  httpClient.delete(`neet-category/${id}`);
