import httpClient from "../utils/httpClient";

export const getArmedForceList = () => httpClient.get('armed-force');

export const createArmedForce = (data) => httpClient.post('armed-force', JSON.stringify(data));

export const updateArmedForce = (data) => httpClient.put(`armed-force/${data.id}`, JSON.stringify(data));

export const deleteArmedForce = (id) => httpClient.delete(`armed-force/${id}`);

