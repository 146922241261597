import httpClient from "../utils/httpClient";

export const getCourseList = () => httpClient.get('course');

export const createCourse = (data) => httpClient.post('course', JSON.stringify(data));

export const updateCourse = (data) => httpClient.put(`course/${data.id}`, JSON.stringify(data));

export const deleteCourse = (id) => httpClient.delete(`course/${id}`);

