import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { getCities } from "../../services/city";
import { getState } from "../../services/states";
import { getInitData } from "../../services/init-data";
import { getAreaOfStateList } from "../../services/areaOfState";
import { createCollege, updateCollege } from "../../services/colleges";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getStateCategoryOfStateList } from "../../services/stateCategoryOfState";

const CollegeCreate = () => {
  const College = useLocation().state;
  const collegeId = College?.id || "";

  const [input, setInput] = useState({
    name: College?.name || "",
    year:
      moment(College?.year).format("YYYY") || moment(Date.now()).format("YYYY"),
    stateId: College?.stateId || "",
    cityId: College?.cityId || "",
    pincode: College?.pincode || "",
    regionId: College?.regionId || "",
    yearOfInception:
      moment(College?.yearOfInception).format("YYYY") ||
      new Date().getFullYear(),
    counsellingSeatId: College?.counsellingSeatId || "",
    eligibilityId: College?.eligibilityId || "",
    courseId: College?.courseId || "",
    institutionTypeId: College?.institutionTypeId || "",
    seatTypeId: College?.seatTypeId || "",
    hostelFee: College?.hostelFee || 0,
    tuitionFee: College?.tuitionFee || 0,
    feeRangeId: College?.feeRangeId || "",
    totalFee: College?.totalFee || 0,
    totalFeePayable: College?.totalFeePayable || "",
    areaId: College?.areaId || "",
    neetCategoryId: College?.neetCategoryId || "",
    stateCategoryId: College?.stateCategoryId || "",
    armedForceId: College?.armedForceId || "",
    minorityId: College?.minorityId || "",
    quotaId: College?.quotaId || "",
    isFemaleSeat: College?.isFemaleSeat || "",
    isPhCandidate: College?.isPhCandidate || "",
    allIndiaRating: College?.allIndiaRating || "",
    stateRating: College?.stateRating || "",
    neetRating: College?.neetRating || "",
    bondYear: College?.bondYear || "",
    bondAmount: College?.bondAmount || 0,
  });
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [areaOfState, setAreaOfState] = useState([]);
  const [stateCategoryOfState, setStateCategoryOfState] = useState([]);
  const [searchedCity, setSearchedCity] = useState([]);
  const [searchedStateCategoryOfState, setSearchedStateCategoryOfState] =
    useState([{ state_category: { category: "" } }]);
  const [searchedAreaOfState, setSearchedAreaOfState] = useState([
    { area: { area: "" } },
  ]);
  const [initData, setInitData] = useState({
    area: [],
    counsellingSeat: [],
    course: [],
    eligibility: [],
    feeRange: [],
    institutionType: [],
    region: [],
    seatType: [],
    neetCategory: [],
    stateCategory: [],
    armedForce: [],
    minority: [],
    quota: [],
  });

  const bondYearData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  useEffect(() => {
    async function getData() {
      getState()
        .then((response) => {
          setState(response.data.result);
        })
        .catch((err) => {
          errorMessage();
        });

      getCities()
        .then((response) => {
          setCities(response.data.result);
        })
        .catch((err) => {
          errorMessage();
        });
      getAreaOfStateList().then((result) => {
        if (result.data.error === false) {
          setAreaOfState(result.data.result);
        }
      });
      getStateCategoryOfStateList().then((result) => {
        if (result.data.error === false) {
          setStateCategoryOfState(result.data.result);
        }
      });
      getInitData()
        .then((response) => {
          if (!response.data.error) {
            setInitData(response.data.result);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
    getData();
  }, []);

  const handleStateChange = (stateId) => {
    if (stateId) {
      const filterStateId = parseInt(stateId, 10);
      const availableState = state.find((s) => s.id === filterStateId);
      const availableStateOfArea = areaOfState.filter((s) => s.stateId === filterStateId);
      const availableStateCategoryOfState = stateCategoryOfState.filter((s) => s.stateId === filterStateId);
      const searchCity = cities.filter((c) => c.stateCode === availableState.code);
      setSearchedCity(searchCity);
      setSearchedAreaOfState(availableStateOfArea);
      setSearchedStateCategoryOfState(availableStateCategoryOfState);
    }
  };  

  useEffect(() => {
    const state = (state) => console.log(state);
    const areaOfState = (areaOfState) => console.log(areaOfState);
    const stateCategoryOfState = (stateCategoryOfState) =>
      console.log(stateCategoryOfState);
      const finalStateId = !College ? input.stateId : College.stateId
    handleStateChange(finalStateId);
  }, [state, areaOfState, stateCategoryOfState]);
  
  let isFemaleSeat = "";
  if (!College ? input.isFemaleSeat === true : College.isFemaleSeat === true) {
    isFemaleSeat = "1";
  } else if (
    !College ? input.isFemaleSeat === false : College.isFemaleSeat === false
  ) {
    isFemaleSeat = "0";
  }
  let isPhCandidate = "";
  if (
    !College ? input.isPhCandidate === true : College.isPhCandidate === true
  ) {
    isPhCandidate = "1";
  } else if (
    !College ? input.isPhCandidate === false : College.isPhCandidate === false
  ) {
    isPhCandidate = "0";
  }

  const handleCollegeData = async (event) => {
    event.preventDefault();
    if (College) {
      updateCollege(collegeId, {
        ...input,
        armedForceId: input.armedForceId === "" ? null : input.armedForceId,
        minorityId: input.minorityId === "" ? null : input.minorityId,
        quotaId: input.quotaId === "" ? null : input.quotaId,
        stateCategoryId:
          input.stateCategoryId === "" ? null : input.stateCategoryId,
        neetCategoryId:
          input.neetCategoryId === "" ? null : input.neetCategoryId,
        isFemaleSeat:
          input.isFemaleSeat !== "" ? input.isFemaleSeat : College.isFemaleSeat,
        isPhCandidate:
          input.isPhCandidate !== ""
            ? input.isPhCandidate
            : College.isPhCandidate,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/colleges");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    } else {

      createCollege({
        ...input,
        sessionYear: input.year,
        armedForceId: input.armedForceId === "" ? null : input.armedForceId,
        minorityId: input.minorityId === "" ? null : input.minorityId,
        quotaId: input.quotaId === "" ? null : input.quotaId,
        stateCategoryId:
          input.stateCategoryId === "" ? null : input.stateCategoryId,
        neetCategoryId:
          input.neetCategoryId === "" ? null : input.neetCategoryId,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/colleges");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    };
  }

  
  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  College {College ? "Edit" : "Create"}
                </h5>
                <form className="g-3" onSubmit={handleCollegeData}>
                  <div className="row mb-3">
                    <div className="col-md-2">
                      <label htmlFor="year" className="form-label">
                        Year
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="year"
                        name="year"
                        value={input.year}
                        onChange={(e) =>
                          setInput({ ...input, year: e.target.value })
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="name" className="form-label">
                        College
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={input.name}
                        onChange={(e) =>
                          setInput({ ...input, name: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="stateId" className="form-label">
                        State
                      </label>
                      <select
                        id="stateId"
                        name="stateId"
                        value={input.stateId}
                        onChange={(e) => {
                          setInput({ ...input, stateId: e.target.value });
                          handleStateChange(e.target.value);
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {state.map((states) => (
                          <option key={states.id} value={states.id}>
                            {states.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="cityId" className="form-label">
                        City
                      </label>
                      <select
                        id="cityId"
                        name="cityId"
                        value={!College ? input.cityId : College.cityId}
                        onChange={(e) =>
                          setInput({ ...input, cityId: e.target.value })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {searchedCity.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="pincode" className="form-label">
                        Pin Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        name="pincode"
                        value={input.pincode}
                        onChange={(e) =>
                          setInput({ ...input, pincode: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="regionId" className="form-label">
                        Region
                      </label>
                      <select
                        id="regionId"
                        name="regionId"
                        value={input.regionId}
                        onChange={(e) =>
                          setInput({ ...input, regionId: e.target.value })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.region.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.region}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="yearOfInception" className="form-label">
                        Year Of Inception
                      </label>
                      <input
                        type="number"
                        min={1900}
                        max={2099}
                        className="form-control"
                        id="yearOfInception"
                        name="yearOfInception"
                        value={input.yearOfInception}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            yearOfInception: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="counsellingSeatId" className="form-label">
                        Counselling Seat
                      </label>
                      <select
                        id="counsellingSeatId"
                        name="counsellingSeatId"
                        value={input.counsellingSeatId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            counsellingSeatId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.counsellingSeat.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.counsellingSeat}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="eligibilityId" className="form-label">
                        Eligibility
                      </label>
                      <select
                        id="eligibilityId"
                        name="eligibilityId"
                        value={input.eligibilityId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            eligibilityId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.eligibility.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.eligibility}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="courseId" className="form-label">
                        Course
                      </label>
                      <select
                        id="courseId"
                        name="courseId"
                        value={input.courseId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            courseId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.course.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.course}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="institutionTypeId" className="form-label">
                        Institution Type
                      </label>
                      <select
                        id="institutionTypeId"
                        name="institutionTypeId"
                        value={input.institutionTypeId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            institutionTypeId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.institutionType.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.institutionType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="seatTypeId" className="form-label">
                        Seat Type
                      </label>
                      <select
                        id="seatTypeId"
                        name="seatTypeId"
                        value={input.seatTypeId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            seatTypeId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.seatType.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.seatType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="feeRangeId" className="form-label">
                        Fee Range
                      </label>
                      <select
                        id="feeRangeId"
                        name="feeRangeId"
                        value={input.feeRangeId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            feeRangeId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {initData.feeRange.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.feeRange}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="tuitionFee" className="form-label">
                        Tuition Fee
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="tuitionFee"
                        name="tuitionFee"
                        min={0}
                        value={input.tuitionFee}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            tuitionFee: e.target.value,
                          })
                        }
                        // onChange={(e) =>
                        //   setInput({
                        //     ...input,
                        //     tuitionFee: e.target.value,
                        //     totalFee:
                        //       parseInt(e.target.value) +
                        //       parseInt(input.hostelFee),
                        //   })
                        // }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="hostelFee" className="form-label">
                        Hostel Fee
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="hostelFee"
                        name="hostelFee"
                        min={0}
                        value={input.hostelFee}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            hostelFee: e.target.value,
                          })
                        }
                        // onChange={(e) =>
                        //   setInput({
                        //     ...input,
                        //     hostelFee: e.target.value,
                        //     totalFee:
                        //       parseInt(e.target.value) +
                        //       parseInt(input.tuitionFee),
                        //   })
                        // }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="totalFeePayable" className="form-label">
                        Total fee payable at admission
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="totalFeePayable"
                        name="totalFeePayable"
                        min={0}
                        value={input.totalFeePayable}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            totalFeePayable: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="totalFee" className="form-label">
                        Total Fee
                      </label>
                      <input
                        type="text"
                        className="form-control input-disabled"
                        id="totalFee"
                        name="totalFee"
                        value={input.totalFee}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            totalFee: e.target.value,
                          })
                        }
                        // disabled
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="areaId" className="form-label">
                        Area
                      </label>
                      <select
                        id="areaId"
                        name="areaId"
                        value={input.areaId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            areaId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {searchedAreaOfState.map((item) => (
                          <option key={item.area.id} value={item.area.id}>
                            {item.area.area}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    {input.counsellingSeatId &&
                      parseInt(input.counsellingSeatId) === 1 && (
                        <div className="col-md-6">
                          <label
                            htmlFor="neetCategoryId"
                            className="form-label"
                          >
                            NEET Category
                          </label>
                          <select
                            id="neetCategoryId"
                            name="neetCategoryId"
                            value={input.neetCategoryId}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                neetCategoryId: e.target.value,
                              })
                            }
                            className="form-select"
                            required
                          >
                            <option value="">Select Option</option>
                            {initData.neetCategory.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.category}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    {input.counsellingSeatId &&
                      parseInt(input.counsellingSeatId) === 2 && (
                        <div className="col-md-6">
                          <label
                            htmlFor="stateCategoryId"
                            className="form-label"
                          >
                            State Category
                          </label>
                          <select
                            id="stateCategoryId"
                            name="stateCategoryId"
                            value={input.stateCategoryId}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                stateCategoryId: e.target.value,
                              })
                            }
                            className="form-select"
                            required
                          >
                            <option value="">Select Option</option>
                            {searchedStateCategoryOfState.map((item) => (
                              <option
                                key={item.state_category.id}
                                value={item.state_category.id}
                              >
                                {item.state_category.category}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="minorityId" className="form-label">
                        Minorities
                      </label>
                      <select
                        id="minorityId"
                        name="minorityId"
                        value={input.minorityId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            minorityId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        {initData.minority.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.minority}
                          </option>
                        ))}
                        <option value="">None</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="armedForceId" className="form-label">
                        Armed Forces
                      </label>
                      <select
                        id="armedForceId"
                        name="armedForceId"
                        value={input.armedForceId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            armedForceId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        {initData.armedForce.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.armedForce}
                          </option>
                        ))}
                        <option value="">None</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="quotaId" className="form-label">
                        Quota
                      </label>
                      <select
                        id="quotaId"
                        name="quotaId"
                        value={input.quotaId}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            quotaId: e.target.value,
                          })
                        }
                        className="form-select"
                        required
                      >
                        {initData.quota.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.quota}
                          </option>
                        ))}
                        <option value="">None</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="isFemaleSeat" className="form-label">
                        Female/General Seat
                      </label>
                      <select
                        id="isFemaleSeat"
                        name="isFemaleSeat"
                        defaultValue={isFemaleSeat}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            isFemaleSeat: e.target.value === "1",
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        <option value="1">FEMALE</option>
                        <option value="0">GENERAL</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="isPhCandidate" className="form-label">
                        PH Candidate
                      </label>
                      <select
                        id="isPhCandidate"
                        name="isPhCandidate"
                        defaultValue={isPhCandidate}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            isPhCandidate: e.target.value === "1",
                          })
                        }
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="allIndiaRating" className="form-label">
                        All India Rating
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={111111}
                        max={999999}
                        className="form-control"
                        id="allIndiaRating"
                        name="allIndiaRating"
                        value={input.allIndiaRating}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            allIndiaRating: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="stateRating" className="form-label">
                        State Rating
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={111111}
                        max={999999}
                        className="form-control"
                        id="stateRating"
                        name="stateRating"
                        value={input.stateRating}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            stateRating: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="neetRating" className="form-label">
                        NEET Rating
                      </label>
                      <input
                        type="number"
                        step={1}
                        min={111111}
                        max={999999}
                        className="form-control"
                        id="neetRating"
                        name="neetRating"
                        value={input.neetRating}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            neetRating: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="bondYear" className="form-label">
                        Bond year
                      </label>
                      <select
                        id="bondYear"
                        name="bondYear"
                        value={parseInt(input.bondYear)}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            bondYear: parseInt(e.target.value),
                          })
                        }
                        className="form-select"
                        required
                      >
                        {bondYearData.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="bondAmount" className="form-label">
                        Bond amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="bondAmount"
                        name="bondAmount"
                        value={input.bondAmount}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            bondAmount: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CollegeCreate;
