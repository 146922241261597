import httpClient from "../utils/httpClient";

export const getStateCategoryOfStateList = () => httpClient.get('state-category-of-state');

export const createStateCategoryOfState = (data) => httpClient.post('state-category-of-state', JSON.stringify(data));

export const deleteStateCategoryOfState = (id, stateCategoryId) =>
  httpClient.delete(
    `state-category-of-state/stateId/${id}/stateCategoryId/${stateCategoryId}`
  );

