const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="copyright">
                © Copyright <strong><span>Neet Navigator</span></strong>. All Rights Reserved
            </div>
        </footer>
    )
};

export default Footer;