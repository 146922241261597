import httpClient from "../utils/httpClient";

export const getRegionList = () => httpClient.get('region');

export const createRegion = (data) => httpClient.post('region', JSON.stringify(data));

export const updateRegion = (data) => httpClient.put(`region/${data.id}`, JSON.stringify(data));

export const deleteRegion = (id) => httpClient.delete(`region/${id}`);

