import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { createColleges, getCollege } from "../../services/colleges";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import styled from "styled-components";
import "../../assets/css/loaderStyle.css";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

function parseCSV(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}

function importCSV(getData) {
  Swal.fire({
    title: "Import CSV File",
    html: '<a href="/assets/college_import.csv" download="sample.csv">Sample File</a>',
    input: "file",
    inputAttributes: {
      autocapitalize: "off",
      accept: ".csv",
      required: true,
    },
    showCancelButton: true,
    confirmButtonText: "Import",
    showLoaderOnConfirm: true,
    preConfirm: (value) => {
      if (value) {
        const reader = new FileReader();
        reader.readAsText(value);
        return (reader.onload = () => {
          const data = reader.result;
          Swal.fire({
            title: "Please Wait...",
            didOpen: () => {
              Swal.showLoading();
            },
          });
          return parseCSV(data).then((inputFileData) => {
            const date = new Date();
            const currentYear = date.getFullYear();
            const updatedInputFileData = inputFileData.map(element => ({...element, sessionYear: currentYear}))
            const collegeData = updatedInputFileData.filter(
              (item) => !!item.name
            );
            return createColleges(collegeData)
              .then((result) => {
                if (result.data.error === false) {
                  successMessage();
                  getData();
                } else {
                  errorMessage();
                }
              })
              .catch((err) => errorMessage(err));
          });
        });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
  });
}

const Export = ({ onExport }) => (
  <Button
    className="move-btn move-btn-re"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </Button>
);

const Import = ({ onImport }) => (
  <Button
    className="move-btn move-btn-su"
    onClick={(e) => onImport(e.target.value)}
  >
    Import
  </Button>
);

const AddCollege = () => (
  <Link className=" btn btn-primary move-btn move-btn-su" to="/college">
    Create
  </Link>
);

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Search College"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

const CollegeList = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false); // Add loading state
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const columns = useMemo(
    () => [
      { name: "#", sortable: true, selector: (row) => row.id },
      {
        name: "College Name",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.name} />,
        selector: (row) => row.name,
      },
      { name: "Year", sortable: true, selector: (row) => row.yearText },
      {
        name: "Year Of Inception",
        sortable: true,
        selector: (row) => row.yearOfInceptionText,
      },
      { name: "City", sortable: true, selector: (row) => row.cityText },
      { name: "State", sortable: true, selector: (row) => row.stateText },
      { name: "Pincode", sortable: true, selector: (row) => row.pincode },
      { name: "Region", sortable: true, selector: (row) => row.regionText },
      { name: "Area", sortable: true, selector: (row) => row.areaText },
      { name: "Course", sortable: true, selector: (row) => row.courseText },
      {
        name: "Institution Type",
        sortable: true,
        selector: (row) => row.institutionTypeText,
      },
      {
        name: "Eligibility",
        sortable: true,
        selector: (row) => row.eligibilityText,
      },
      {
        name: "Counselling Seat",
        sortable: true,
        selector: (row) => row.counsellingSeatText,
      },
      { name: "Category", sortable: true, selector: (row) => row.categoryText },
      {
        name: "Seat Type",
        sortable: true,
        selector: (row) => row.seatTypeText,
      },
      {
        name: "Female/General Seat",
        sortable: true,
        selector: (row) => row.isFemaleSeatText,
      },
      {
        name: "NEET Rating",
        sortable: true,
        selector: (row) => row.neetRating,
      },
      {
        name: "PH Candidate",
        sortable: true,
        selector: (row) => row.isPhCandidateText,
      },
      {
        name: "Armed Forces",
        sortable: true,
        selector: (row) => row.armedForceText,
      },
      { name: "Minority", sortable: true, selector: (row) => row.minorityText },
      { name: "Quota", sortable: true, selector: (row) => row.quotaText },
      {
        name: "Fee Range",
        sortable: true,
        selector: (row) => row.feeRangeText,
      },

      {
        name: "Tuition Fee",
        sortable: true,
        selector: (row) => row.tuitionFee,
      },
      { name: "Hostel Fee", sortable: true, selector: (row) => row.hostelFee },
      { name: "Total Fee", sortable: true, selector: (row) => row.totalFee },
      {
        name: "Total Fee Payable",
        sortable: true,
        selector: (row) => row.totalFeePayable,
      },
      {
        name: "Bond Year",
        sortable: true,
        selector: (row) => row.bondYear,
      },
      {
        name: "Bond Amount",
        sortable: true,
        selector: (row) => row.bondAmount,
      },
      {
        name: "Edit",
        selector: (row) => (
          <Link
            type="button"
            to={"/college"}
            state={row}
            className="btn btn-warning"
          >
            <i className="bi bi-pencil-square"></i>
          </Link>
        ),
      },
    ],
    []
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const actionsMemo = useMemo(
    () => (
      <>
        <Import onImport={() => importCSV(getData)} />
        <Export onExport={() => downloadCSV(data)} />
        <AddCollege />
      </>
    ),
    [data]
  );

  async function getData(year = null) {
    setLoading(true); 
    getCollege(year)
      .then((response) => {
        if (!response.data.error) {
          setData(
            response.data.result.map((item) => ({
              yearText: item.year.split("-")[0],
              yearOfInceptionText: item.yearOfInception.split("-")[0],
              cityText: item.city.name,
              stateText: item.state.name,
              regionText: item.region.region,
              areaText: item.area.area,
              courseText: item.course.course,
              eligibilityText: item.eligibility.eligibility,
              institutionTypeText: item.institution_type.institutionType,
              counsellingSeatText: item.counselling_seat.counsellingSeat,
              seatTypeText: item.seat_type.seatType,
              isFemaleSeatText: item.isFemaleSeat ? "FEMALE" : "GENERAL",
              categoryText:
                item.counselling_seat.counsellingSeat === "MCC"
                  ? item.neet_category.category
                  : item.state_category.category,
              isPhCandidateText: item.isPhCandidate ? "YES" : "NO",
              armedForceText: item.armed_force
                ? item.armed_force.armedForce
                : "None",
              minorityText: item.minority ? item.minority.minority : "None",
              quotaText: item.quota ? item.quota.quota : "None",
              feeRangeText: item.fee_range ? item.fee_range.feeRange : "None",
              ...item,
            }))
          );
        }
      })
      .catch((err) => errorMessage(err))
      .finally(() => {
        setLoading(false); // Set loading to false after data is loaded
        setPending(false);
      });
  }

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const yearList = [];
    for (let i = 2023; i <= currentYear; i++) {
      yearList.push(i);
    }
    getData(currentYear);
    setSelectedYear(currentYear);
    setYears(yearList);
  }, []);

  const handleYearChange = (e) => {
    setPending(true); 
    getData(e.target.value);
    setSelectedYear(e.target.value);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">College List</h5>
                <div className="g-3">
                  <div className="row mb-3">
                    <div className="col-md-2">
                      <label htmlFor="year" className="form-label">
                        Year
                      </label>
                      <select
                        id="year"
                        name="year"
                        className="form-select"
                        onChange={handleYearChange}
                        value={selectedYear}
                        disabled={loading} // Disable the select when loading is true
                      >
                        <option value="">Select Option</option>
                        {years.map((element) => (
                          <option key={element} value={element}>
                            {element}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    progressPending={pending}
                    actions={actionsMemo}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CollegeList;
