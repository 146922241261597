import React, { useEffect, useState } from 'react'
import { deleteAreaOfState, getAreaOfStateList } from '../../services/areaOfState';
import { errorMessage, successMessage } from '../../utils/alertMessage';

export default function AreaOfStateList() {
  const [data, setData] = useState([]);
  const fetchData = () => {
    getAreaOfStateList()
      .then(result => {
        if (result.data.error === false) {
          setData(result.data.result);
        }
      });
  }
  useEffect(() => fetchData(), []);

  const handleRemove = (id, areaId) => {
    deleteAreaOfState(id, areaId)
    .then((result) => {
      if (result.data.error === false) {
        successMessage();
        fetchData();
      } else {
        errorMessage();
      }
    });
  }

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Area Of State List</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>State</th>
                      <th>Area</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.state.name}</td>
                        <td>{item.area.area}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleRemove(item.stateId, item.areaId)
                            }
                            className="btn btn-danger"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

