import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { createNeetCategory, updateNeetCategory } from "../../services/neetCategory";
import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function NeetCategory() {
  const navigate = useNavigate();
  const titleRef = useRef();
  const { state } = useLocation();
  const defaultValue = state?.item
    ? { title: state.item.category }
    : { title: "" };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { category: titleRef.current.value };
      let result = {};
      if (state?.item) {
        result = await updateNeetCategory({ ...payload, id: state.item.id });
      } else {
        result = await createNeetCategory(payload);
      }
      if (result.data.error === false) {
        successMessage();
        navigate("/neetCategory/list");
      } else {
        errorMessage();
      }
    } catch (error) {
      errorMessage();
    }
  };
  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">NEET Category</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="name" className="form-label">
                        Title
                      </label>
                      <input
                        ref={titleRef}
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        defaultValue={defaultValue.title}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
