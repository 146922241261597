import httpClient from "../utils/httpClient";

export const getNeetCategoryCutoffList = () => httpClient.get("neet-category-cutoff");

export const createNeetCategoryCutoff = (data) =>
  httpClient.post("neet-category-cutoff", JSON.stringify(data));

export const updateNeetCategoryCutoff = (data) =>
  httpClient.put(`neet-category-cutoff/${data.id}`, JSON.stringify(data));

export const deleteNeetCategoryCutoff = (id) =>
  httpClient.delete(
    `neet-category-cutoff/${id}`
  );

