import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { findCollege } from '../../services/colleges';

const CollegeListData = () => {
  const [collegeData, setCollegeData] = useState({
    name: "",
    year: "",
    pincode: "",
    totalFee: "",
    hostelFee: "",
    tuitionFee: "",
    neetRating: "",
    stateRating: "",
    isFemaleSeat: "",
    isPhCandidate: "",
    allIndiaRating: "",
    totalFeePayable: "",
    yearOfInception: "",

    city: { name: "" },
    area: { area: "" },
    state: { name: "" },
    quota: { quota: "" },
    course: { course: "" },
    region: { region: "" },
    minority: { minority: "" },
    seat_type: { seatType: "" },
    fee_range: { feeRange: "" },
    armed_force: { armedForce: "" },
    neet_category: { category: "" },
    eligibility: { eligibility: "" },
    state_category: { category: "" },
    institution_type: { feeRange: "" },
    counselling_seat: { counsellingSeat: "" },
  });
  
  const location = useLocation();
  const collegeId = location.state.id;
  
  useEffect(() => {
    async function getData() {
      findCollege(collegeId)
        .then((response) => {
          if (!response.data.error) {
            setCollegeData(response.data.result);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getData();
  }, [collegeId]);

  let isFemaleSeat = collegeData.isFemaleSeat === true ? "FEMALE" : "GENERAL";
  let isPhCandidate = collegeData.isPhCandidate === true ? "YES" : "NO";
    return (
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>College Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">College</li>
              <li className="breadcrumb-item active">View All Details</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row align-items-top">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{collegeData.name}</h5>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="year"
                          className="col-sm-4 form-label college-label"
                        >
                          Year
                        </label>
                        <div className="col-sm-6">
                          <p>{moment(collegeData.year).format("YYYY")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="row">
                      <label
                        htmlFor="name"
                        className="col-sm-2 form-label college-label"
                      >
                        College
                      </label>
                      <div className="col-sm-10">
                        <p>{collegeData.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="stateId"
                        className="col-sm-4 form-label college-label"
                      >
                        State
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.state.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="cityId"
                        className="col-sm-4 form-label college-label"
                      >
                        City
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.city.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="pincode"
                        className="col-sm-4 form-label college-label"
                      >
                        Pin Code
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.pincode}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="regionId"
                        className="col-sm-4 form-label college-label"
                      >
                        Region
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.region.region}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="yearOfInception"
                        className="col-sm-4 form-label college-label"
                      >
                        Year Of Inception
                      </label>
                      <div className="col-sm-6">
                        <p>
                          {moment(collegeData.yearOfInception).format("YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="counsellingSeatId"
                        className="col-sm-4 form-label college-label"
                      >
                        Counselling Seat
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.counselling_seat.counsellingSeat}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="eligibilityId"
                        className="col-sm-4 form-label college-label"
                      >
                        Eligibility
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.eligibility.eligibility}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="courseId"
                        className="col-sm-4 form-label college-label"
                      >
                        Course
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.course.course}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="institutionTypeId"
                        className="col-sm-4 form-label college-label"
                      >
                        Institution Type
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.institution_type.institutionType}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="seatTypeId"
                        className="col-sm-4 form-label college-label"
                      >
                        Seat Type
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.seat_type.seatType}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="feeRange"
                        className="col-sm-4 form-label college-label"
                      >
                        FEE Range
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.fee_range.feeRange}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="tuitionFee"
                        className="col-sm-4 form-label college-label"
                      >
                        Tution Fee
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.tuitionFee}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="hostelFee"
                        className="col-sm-4 form-label college-label"
                      >
                        Hostel Fee
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.hostelFee}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="totalFeePayable"
                        className="col-sm-4 form-label college-label"
                      >
                        Total fee payable at admission
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.totalFeePayable}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="totalFee"
                        className="col-sm-4 form-label college-label"
                      >
                        Total Fee
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.totalFee}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="areaId"
                        className="col-sm-4 form-label college-label"
                      >
                        Area
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.area.area}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        NEET Category
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.neet_category.category}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        State Category
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.state_category.category}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        Armed Forces
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.armed_force.armedForce}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        Minority
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.minority.minority}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        Quota
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.quota.quota}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor=""
                        className="col-sm-4 form-label college-label"
                      >
                        Ph Candidate
                      </label>
                      <div className="col-sm-6">
                        <p>{isPhCandidate}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="femaleGeneralSeat"
                        className="col-sm-4 form-label college-label"
                      >
                        Female/General Seat
                      </label>
                      <div className="col-sm-6">
                        <p>{isFemaleSeat}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="allIndiaRating"
                        className="col-sm-4 form-label college-label"
                      >
                        ALL INDIA Rating
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.allIndiaRating}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="stateRating"
                        className="col-sm-4 form-label college-label"
                      >
                        STATE Rating
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.stateRating}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="neetRating"
                        className="col-sm-4 form-label college-label"
                      >
                        Neet Rating
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.neetRating}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="bondYear"
                        className="col-sm-4 form-label college-label"
                      >
                        Bond year
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.bondYear}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label
                        htmlFor="bondAmount"
                        className="col-sm-4 form-label college-label"
                      >
                        Bond amount
                      </label>
                      <div className="col-sm-6">
                        <p>{collegeData.bondAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default CollegeListData;