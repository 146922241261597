import React, { useEffect, useMemo, useState } from "react";
import { getAllEnquiries } from "../../services/userEnquiries";
import DataTable from "react-data-table-component";
import { errorMessage } from "../../utils/alertMessage";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment/moment";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "user_leads.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <Button
    className="move-btn move-btn-re"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </Button>
);

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Search EnquiryType"
      aria-label="Search EnquiryType"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

export default function UserEnquiries() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const columns = useMemo(
    () => [
      {
        name: "Id",
        sortable: true,
        width: "80px",
        cell: (row) => <CustomTitle title={row.ID} />,
        selector: (row) => row.ID,
      },
      {
        name: "Name",
        sortable: true,
        width: "160px",
        cell: (row) => <CustomTitle title={row.Name} />,
        selector: (row) => row.Name,
      },
      {
        name: "Mobile",
        sortable: true,
        width: "120px",
        cell: (row) => <CustomTitle title={row.Mobile} />,
        selector: (row) => row.Mobile,
      },
      {
        name: "EnquiryType",
        sortable: true,
        width: "300px",
        selector: (row) => row.EnquiryType,
      },
      {
        name: "Email",
        sortable: true,
        width: "300px",
        selector: (row) => row.Email,
      },
      {
        name: "MobileVerified",
        sortable: true,
        width: "120px",
        selector: (row) => row.MobileVerified,
      },
      {
        name: "Status",
        sortable: true,
        width: "120px",
        selector: (row) => row.Status,
      },
      {
        name: "UserRoleId",
        sortable: true,
        width: "120px",
        selector: (row) => row.UserRoleId,
      },
      {
        name: "CreatedAt",
        sortable: true,
        selector: (row) => row.CreatedAt,
      },
      {
        name: "UpdatedAt",
        sortable: true,
        width: "250px",
        selector: (row) => row.UpdatedAt,
      },
    ],
    []
  );

   const [filterText, setFilterText] = React.useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
   const filteredItems = data.filter(
     (item) => item.EnquiryType && item.EnquiryType.toLowerCase().includes(filterText.toLowerCase())
   );

   const subHeaderComponentMemo = React.useMemo(() => {
     const handleClear = () => {
       if (filterText) {
         setResetPaginationToggle(!resetPaginationToggle);
         setFilterText("");
       }
     };

     return (
       <FilterComponent
         onFilter={(e) => setFilterText(e.target.value)}
         onClear={handleClear}
         filterText={filterText}
       />
     );
   }, [filterText, resetPaginationToggle]);

  const actionsMemo = useMemo(
    () => (
      <React.Fragment>
        <Export onExport={() => downloadCSV(data)} />
      </React.Fragment>
    ),
    [data]
  );

  const fetchData = () => {
    getAllEnquiries().then((result) => {
      if (result.data.error === false) {
        setData(
          result.data.result.map((item, index) => ({
            ID: index + 1,
            Name: item.name,
            Mobile: item.mobile,
            EnquiryType: item.enquiryType,
            Email: item.email,
            MobileVerified: item.mobileVerified,
            Status: item.status,
            UserRoleId: item.userRoleId,
            CreatedAt: moment(item.createdAt).format("L LTS"),
            UpdatedAt: moment(item.updatedAt).format("L LTS"),
          }))
        );
      }
    })
    .catch((err) => errorMessage())
    .finally(() => setPending(false));
  };
  useEffect(() => fetchData(), []);

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">User Enquiries</h5>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  progressPending={pending}
                  actions={actionsMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}