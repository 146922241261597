import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";
import httpClient from "../utils/httpClient";

export const createCollege = ({
  name,
  year,
  stateId,
  cityId,
  pincode,
  regionId,
  yearOfInception,
  counsellingSeatId,
  eligibilityId,
  courseId,
  institutionTypeId,
  seatTypeId,
  feeRangeId,
  tuitionFee,
  hostelFee,
  totalFeePayable,
  totalFee,
  areaId,
  neetCategoryId,
  stateCategoryId,
  armedForceId,
  minorityId,
  quotaId,
  isFemaleSeat,
  isPhCandidate,
  allIndiaRating,
  stateRating,
  neetRating,
  bondYear,
  bondAmount,
  sessionYear
}) => {
  return axios({
    method: "post",
    url: BASE_URL + "college",
    headers: getHeaders(),
    data: JSON.stringify({
      name,
      year,
      stateId,
      cityId,
      pincode,
      regionId,
      yearOfInception,
      counsellingSeatId,
      eligibilityId,
      courseId,
      institutionTypeId,
      seatTypeId,
      feeRangeId,
      tuitionFee,
      hostelFee,
      totalFeePayable,
      totalFee,
      areaId,
      neetCategoryId,
      stateCategoryId,
      armedForceId,
      minorityId,
      quotaId,
      isFemaleSeat,
      isPhCandidate,
      allIndiaRating,
      stateRating,
      neetRating,
      bondYear,
      bondAmount,
      sessionYear
    }),
  });
};

export const getCollege = (year = null) => {
  const queryString = year ? `?sessionYear=${year}` : '';
  return httpClient.get("college" + queryString);
}

export const findCollege = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "college/" + id,
    headers: getHeaders(),
  });
};


export const updateCollege = (id , {
  name,
  year,
  stateId,
  cityId,
  pincode,
  regionId,
  yearOfInception,
  counsellingSeatId,
  eligibilityId,
  courseId,
  institutionTypeId,
  seatTypeId,
  feeRangeId,
  tuitionFee,
  hostelFee,
  totalFeePayable,
  totalFee,
  areaId,
  neetCategoryId,
  stateCategoryId,
  armedForceId,
  minorityId,
  quotaId,
  isFemaleSeat,
  isPhCandidate,
  allIndiaRating,
  stateRating,
  neetRating,
  bondYear,
  bondAmount,
}) => {
  return axios({
    method: "put",
    url: BASE_URL + "college/" + id,
    headers: getHeaders(),
    data: JSON.stringify({
      name,
      year,
      stateId,
      cityId,
      pincode,
      regionId,
      yearOfInception,
      counsellingSeatId,
      eligibilityId,
      courseId,
      institutionTypeId,
      seatTypeId,
      feeRangeId,
      tuitionFee,
      hostelFee,
      totalFeePayable,
      totalFee,
      areaId,
      neetCategoryId,
      stateCategoryId,
      armedForceId,
      minorityId,
      quotaId,
      isFemaleSeat,
      isPhCandidate,
      allIndiaRating,
      stateRating,
      neetRating,
      bondYear,
      bondAmount,
    }),
  });
};


export const createColleges = (data) => httpClient.post('college/bulk', JSON.stringify(data));