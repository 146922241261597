import httpClient from "../utils/httpClient";

export const getStateCategoryList = () => httpClient.get("state-category");

export const createStateCategory = (data) =>
  httpClient.post("state-category", JSON.stringify(data));

export const updateStateCategory = (data) =>
  httpClient.put(`state-category/${data.id}`, JSON.stringify(data));

export const deleteStateCategory = (id) =>
  httpClient.delete(`state-category/${id}`);
