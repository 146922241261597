import httpClient from "../utils/httpClient";

export const getQuotaList = () => httpClient.get('quota');

export const createQuota = (data) => httpClient.post('quota', JSON.stringify(data));

export const updateQuota = (data) => httpClient.put(`quota/${data.id}`, JSON.stringify(data));

export const deleteQuota = (id) => httpClient.delete(`quota/${id}`);

