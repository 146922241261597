import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";
import httpClient from "../utils/httpClient";

export const getStudent = () => {
  return axios({
    method: "get",
    url: BASE_URL + "student",
    headers: getHeaders(),
  });
};

export const findStudent = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "student/" + id,
    headers: getHeaders(),
  });
};

export const deleteStudent = (id) => httpClient.delete(`student/${id}`);
