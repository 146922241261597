import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const getCities = () =>{
  return axios({
    method: "get",
    url: BASE_URL + "city",
    headers:  getHeaders(),
  });
}