import React, { useRef } from 'react'
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/authSlice';
import { verifyPassword } from '../services/auth';
import { setUserData } from '../utils/localStorage';
import { errorMessage } from '../utils/alertMessage';

const Login = () => {
  const dispatch = useDispatch();
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    verifyPassword({ 
        username: usernameRef.current.value, 
        password: passwordRef.current.value 
      })
        .then(({data}) => {
          if (data.error === false) {
            const { result: { token, id, userRoleId } } = data;
            if(userRoleId === 2){
              setUserData({token, userId: id});
              dispatch(setToken(token));
            } else {
              errorMessage('Invalid Access');
            }
          } else {
            errorMessage('Invalid Credentials');
          }
        })
        .catch((err) => {
          console.log('err', err);
          errorMessage();
        });
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="logo d-flex align-items-center w-auto">
                    <span className="d-none d-lg-block">
                      <img
                        src="/image/blue-logo-final 1.png"
                        alt="NEET Navigator"
                        style={{ maxHeight: "100px" }}
                      />
                    </span>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-2 pb-2 text-center">
                      <h5 className="card-title pb-0 fs-4">Login to Admin</h5>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-12">
                        <label htmlFor="mobile" className="form-label">
                          Username
                        </label>
                        <div className="input-group has-validation">
                          <input
                            ref={usernameRef}
                            type="text"
                            name="username"
                            className="form-control"
                            id="username"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="otp" className="form-label">
                          Password
                        </label>
                        <input
                          ref={passwordRef}
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Login