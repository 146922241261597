import { getUserData } from "../utils/localStorage";

const getHeaders = () => {
  const { token } = getUserData();
  return {
    "app-access-token": 'Bearer ' + token,
    "Content-Type": "application/json",
  }
};
export default getHeaders;
