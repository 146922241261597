import httpClient from "../utils/httpClient";

export const getEligibilityList = () => httpClient.get('eligibility');

export const createEligibility = (data) => httpClient.post('eligibility', JSON.stringify(data));

export const updateEligibility = (data) => httpClient.put(`eligibility/${data.id}`, JSON.stringify(data));

export const deleteEligibility = (id) => httpClient.delete(`eligibility/${id}`);

