import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import '../../components/loader/loader.css';

const Loader = () => {
    return (
        <div className="loader-main-div">
            <div className="loader-second-div">
                <ClipLoader
                    color={'#eb3b2e'}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    )
}

export default Loader;

