import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { getState } from "../../services/states";
import { getAreaList } from "../../services/area";
import { createAreaOfState } from "../../services/areaOfState";
import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function AreaOfState() {
  const navigate = useNavigate();

  const [stateList, setStateList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  useEffect(() => {
    async function getData() {
      getState()
        .then((response) => {
          if (response.data.error === false) {
            setStateList(response.data.result);
          }
        })
        .catch((err) => {
          errorMessage();
        });

      getAreaList()
        .then((response) => {
          if (response.data.error === false) {
            setAreaList(
              response.data.result.map((area) => ({
                value: area.id,
                label: area.area,
              }))
            );
          }
        })
        .catch((err) => {
          errorMessage(err);
        });
    }
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = selectedArea.map((area) => ({
      areaId: area.value,
      stateId: selectedState,
    }));
    createAreaOfState(payload)
      .then((response) => {
        if (response.data.error === false) {
          successMessage();
          navigate("/areaOfState/list");
        } else {
          if (
            response.data.errors.errors[0].message === "PRIMARY must be unique"
          ) {
            errorMessage("Selected Data is already present");
          } else {
            errorMessage();
          }
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const handleSelect = (data) => setSelectedArea(data);

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Area Of State</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="stateId" className="form-label">
                        State
                      </label>
                      <select
                        id="stateId"
                        name="stateId"
                        className="form-select"
                        onChange={(e) => setSelectedState(e.target.value)}
                        required
                      >
                        <option>Please select</option>
                        {stateList.map((states) => (
                          <option key={states.id} value={states.id}>
                            Id {states.id} , {states.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="areaId" className="form-label">
                        Area Of State
                      </label>
                      <Select
                        options={areaList}
                        value={selectedArea}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
