import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { createSeatType, updateSeatType } from '../../services/seatType';
import { errorMessage, successMessage } from '../../utils/alertMessage';

export default function SeatType() {
    const navigate = useNavigate();
    const titleRef = useRef();
    const { state } = useLocation();
    const defaultValue = state?.item ? { title: state.item.seatType } : { title: '' };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = { seatType: titleRef.current.value };
            let result = {};
            if (state?.item) {
                result = await updateSeatType({ ...payload, id: state.item.id });
            } else {
                result = await createSeatType(payload);
            }
            if (result.data.error === false) {
                successMessage();
                navigate('/seatType/list');
            } else {
                errorMessage();
            }
        } catch (error) {
            errorMessage();
        }
    };
    return (
        <main id="main" className="main">
            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">SeatType</h5>
                                <form onSubmit={handleSubmit}>
                                    <div className='row mb-3'>
                                        <div className="col-md-12">
                                            <label htmlFor="name" className="form-label">
                                                Title
                                            </label>
                                            <input
                                                ref={titleRef}
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                name="title"
                                                defaultValue={defaultValue.title}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <button type="submit" className="move-btn move-btn-su">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
