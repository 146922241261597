import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { deleteRankPredictor, getRankPredictorList } from "../../services/rankPredictor";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import styled from "styled-components";

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="number"
      placeholder="Search Marks"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

export default function RankPredictorList() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
  const columns = useMemo(
    () => [
      {
        name: "Id",
        sortable: true,
        cell: (row) => <CustomTitle title={row.id} />,
        selector: (row) => row.id,
      },
      {
        name: "Year",
        sortable: true,
        cell: (row) => <CustomTitle title={row.year} />,
        selector: (row) => row.year,
      },
      {
        name: "Marks",
        sortable: true,
        cell: (row) => <CustomTitle title={row.marks} />,
        selector: (row) => row.marks,
      },
      {
        name: "Minimum Rank",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.minRank} />,
        selector: (row) => row.minRank,
      },
      {
        name: "Maximum Rank",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.maxRank} />,
        selector: (row) => row.maxRank,
      },
      {
        name: "Edit",
        selector: (row) => (
          <Link
            to={"/rankPredictor/edit"}
            state={row}
            className="btn btn-warning"
          >
            <i className="bi bi-pencil-square"></i>
          </Link>
        ),
      },
      {
        name: "Delete",
        selector: (row) => (
          <Link
            type="button"
            onClick={() => handleRemove(row.id)}
            className="btn btn-danger"
          >
            <i className="bi bi-trash"></i>
          </Link>
        ),
      },
    ],
    []
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.marks && item.marks.toString().includes(filterText.toString())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const fetchData = (year = null) => {
    getRankPredictorList(year)
      .then((result) => {
        if (result.data.error === false) {
          setData(result.data.result);
        }
      })
      .catch((err) => errorMessage())
      .finally(() => setPending(false));
  };

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const yearList = [];
    for (let i = 2023; i <= currentYear; i++) {
      yearList.push(i);
    }
    fetchData();
    // getData(currentYear);
    setSelectedYear(currentYear);
    setYears(yearList);
  }, []);

   const handleYearChange = (e) => {
     setPending(true);
     fetchData(e.target.value);
     setSelectedYear(e.target.value);
   };


  const handleRemove = (id) => {
    deleteRankPredictor(id).then((result) => {
      if (result.data.error === false) {
        successMessage();
        fetchData();
      } else {
        errorMessage();
      }
    });
  };

   return (
     <main id="main" className="main">
       <section className="section">
         <div className="row">
           <div className="col-lg-12">
             <div className="card">
               <div className="card-body">
                 <h5 className="card-title">Rank Predictor</h5>
                 <div className="g-3">
                   <div className="row mb-3">
                     <div className="col-md-2">
                       <label htmlFor="year" className="form-label">
                         Year
                       </label>
                       <select
                         id="year"
                         name="year"
                         className="form-select"
                         onChange={handleYearChange}
                         value={selectedYear}
                        //  disabled={loading} // Disable the select when loading is true
                       >
                         <option value="">Select Option</option>
                         {years.map((element) => (
                           <option key={element} value={element}>
                             {element}
                           </option>
                         ))}
                       </select>
                     </div>
                   </div>
                 </div>
                 <DataTable
                   columns={columns}
                   data={filteredItems}
                   progressPending={pending}
                   pagination
                   paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                   subHeader
                   subHeaderComponent={subHeaderComponentMemo}
                   persistTableHead
                 />
               </div>
             </div>
           </div>
         </div>
       </section>
     </main>
   );
}

//   return (
//     <main id="main" className="main">
//       <section className="section">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="card">
//               <div className="card-body">
//                 <h5 className="card-title">Score Rank Ranges</h5>
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>Id</th>
//                       <th>Score</th>
//                       <th>Minimum NEET Rank</th>
//                       <th>Maximum NEET Rank</th>
//                       <th>Edit</th>
//                       <th>Remove</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {data.map((item, index) => (
//                       <tr key={index}>
//                         <td>{index + 1}</td>
//                         <td>{item.score}</td>
//                         <td>{item.minNeetRank}</td>
//                         <td>{item.maxNeetRank}</td>
//                         <td>
//                           <Link
//                             to={"/rankPredictor/edit"}
//                             state={{ item }}
//                             className="btn btn-warning"
//                           >
//                             <i className="bi bi-pencil-square"></i>
//                           </Link>
//                         </td>
//                         <td>
//                           <button
//                             onClick={() => handleRemove(item.id)}
//                             className="btn btn-danger"
//                           >
//                             <i className="bi bi-trash"></i>
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </main>
//   );
// }
