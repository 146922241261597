import Swal from "sweetalert2";
import alertConfig from "../constants/alertConfig";

export const successMessage = (title = '') => {
    Swal.fire({
        ...alertConfig,
        title: title ? title : alertConfig.title
    });
};

export const errorMessage = (title = '') => {
    Swal.fire({
        ...alertConfig,
        icon: 'error',
        title: title ? title : 'Something went wrong, please try again later.'
    });
};

export const warningMessage = (title = '') => {
    Swal.fire({
        ...alertConfig,
        icon: 'warning',
        title: title ? title : 'Warning'
    });
};

export const dataLoadingMessage = (title = "") => {
  Swal.fire({
    title: "Please Wait... List is Loading",
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

