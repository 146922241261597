import axios from 'axios';
import httpClient from './httpClient';
import { store } from '../redux/store';
import getHeaders from '../constants/headers';
import { errorMessage } from './alertMessage';
import { removeUserData } from './localStorage';
import { clearToken } from '../redux/authSlice';

const UNAUTHORIZED = 401;
const { dispatch } = store;

export const requestInterceptorSuccess = (config) => {
    config.headers = getHeaders();
    return config;
};

export const requestInterceptorFailure = (error) => Promise.reject(error);

export const responseInterceptorSuccess = response => {
    const { code } = response.data;
    if (code === UNAUTHORIZED) {
        removeUserData();
        dispatch(clearToken());
        errorMessage('Session Expired');
    }
    return response;
};

export const responseInterceptorFailure = error => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
        removeUserData();
        dispatch(clearToken());
    }
    return Promise.reject(error);
};

const interceptor = (instance) => {
    instance.interceptors.request.use(requestInterceptorSuccess, requestInterceptorFailure);

    instance.interceptors.response.use(responseInterceptorSuccess, responseInterceptorFailure);
};

const applyInterceptor = () => {
    interceptor(axios);
    interceptor(httpClient);
}

export default applyInterceptor;