import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/quill/quill.snow.css';
import './assets/vendor/quill/quill.bubble.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/simple-datatables/style.css'
import './assets/css/style.css';

import App from './App';
import { store } from "./redux/store";
import applyInterceptor from './utils/interceptor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

applyInterceptor();