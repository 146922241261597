import React, { useEffect, useState } from "react";
import { getOrder } from "../services/payment";
import { errorMessage } from "../utils/alertMessage";
import { Link } from "react-router-dom";
import { getUser } from "../services/user";

const Dashboard = () => {
  const [data, setData] = useState([{}]);
  const [studentData, setStudentData] = useState([]);

  const [isOpenSale, setIsOpenSale] = useState(true);
  const [isOpenRevenue, setIsOpenRevenue] = useState(true);
  const [isOpenCustomer, setIsOpenCustomer] = useState(true);
  const [saleMenu, setSaleMenu] = useState("Today");
  const [saleMenuValue, setSaleMenuValue] = useState("");
  const [revenueMenu, setRevenueMenu] = useState("Today");
  const [revenueMenuValue, setRevenueMenuValue] = useState("");
  const [customerMenu, setCustomerMenu] = useState("Today");
  const [customerMenuValue, setCustomerMenuValue] = useState("");

  async function getData() {
    getOrder()
      .then((response) => {
        if (!response.data.error) {
          setData(
            response.data.result.map((item, index) => ({
              ID: index + 1,
              billing_name: item.billing_name,
              billing_tel: item.billing_tel,
              order_id: item.order_id,
              amount: item.amount,
              tracking_id: item.tracking_id,
              bank_ref_no: item.bank_ref_no,
              order_status: item.order_status,
              payment_mode: item.payment_mode,
              trans_date: item.trans_date,
              billing_address: item.billing_address,
              billing_city: item.billing_city,
              billing_state: item.billing_state,
              billing_zip: item.billing_zip,
              billing_email: item.billing_email,
              merchant_param3: item.merchant_param3,
              merchant_param4: item.merchant_param4,
              merchant_param5: item.merchant_param5,
            }))
          );
        }
      })
      .catch((err) => errorMessage(err));
  }

  const fetchData = () => {
    getUser()
      .then((result) => {
        if (result.data.error === false) {
          const setValue = result.data.result.filter(
            (item) => item.userRoleId === 1
          );
          setStudentData(
            setValue.map((item, index) => ({
              ID: index + 1,
              Name: item.name,
              Mobile: item.mobile,
              Email: item.email,
              Gender: item.student?.gender,
              DOB: item.student?.dob ? item.student?.dob.split("T")[0] : "N/A",
              Address: item.student?.address?.address
                ? item.student?.address?.address
                : "N/A",
              Pincode: item.student?.address?.pincode,
              City: item.student?.address?.city?.name,
              State: item.student?.address?.state?.name,
              NeetCategory: item.student?.neet_category?.category,
              StateCategory: item.student?.state_category?.category,
              State10th: item.student?.state10th?.name,
              State12th: item.student?.state12th?.name,
              PhCandidate: item.student
                ? item.student?.isPhCandidate === false
                  ? "NO"
                  : "YES"
                : "",
              Area: item.student?.area?.area,
              ArmedForce: item.student?.armed_force?.armedForce,
              Quota: item.student?.quota?.quota,
              Minority: item.student?.minority?.minority,
              StudentCreatedAt: moment(item.createdAt).format("L LTS"),
              StudentUpdatedAt: moment(item.updatedAt).format("L LTS"),
            }))
          );
        }
      })
      .catch((err) => errorMessage());
  };

  useEffect(() => {
    getData();
    fetchData();
  }, []);

  const successPaymentSale = data.filter((c) => c.order_status === "Success");

  const OverAllSale = successPaymentSale.length;
  const OverAllRevenue = successPaymentSale.reduce(
    (sum, item) => sum + parseInt(item.amount),
    0
  );
  const OverAllStudent = studentData.length;

  const moment = require("moment");

  // Get the current date using moment
  const currentDate = moment().format("L");

  // Filter transactions for today
  const todayTransactions = data.filter((transaction) => {
    const transactionDate = moment(
      transaction.trans_date,
      "DD/MM/YYYY HH:mm:ss"
    ).format("L");
    return (
      transaction.order_status === "Success" && transactionDate === currentDate
    );
  });
  const numberOfTodayTransactions = todayTransactions.length;
  const sumOfTodayAmounts = todayTransactions.reduce(
    (sum, transaction) => sum + parseInt(transaction.amount),
    0
  );

  // Filter student for today
  const todayStudent = studentData.filter((student) => {
    const studentDate = moment(
      student.StudentCreatedAt,
      "MM/DD/YYYY HH:mm:ss"
    ).format("L");
    return studentDate === currentDate;
  });
  const numberOfTodayStudent = todayStudent.length;

  // Calculate the date a week ago using moment
  const weekAgoDate = moment().subtract(7, "days").format("L");

  // Filter transactions for a week ago
  const weekAgoTransactions = data.filter((transaction) => {
    const transactionDate = moment(
      transaction.trans_date,
      "DD/MM/YYYY HH:mm:ss"
    ).format("L");
    return (
      transaction.order_status === "Success" &&
      moment(transactionDate).isBetween(
        weekAgoDate,
        currentDate,
        undefined,
        "[]"
      )
    );
  });
  const numberOfWeekAgoTransactions = weekAgoTransactions.length;
  const sumOfWeekAgoAmounts = weekAgoTransactions.reduce(
    (sum, transaction) => sum + parseInt(transaction.amount),
    0
  );
  // Filter student for a week ago
  const weekAgoStudent = studentData.filter((student) => {
    const studentDate = moment(
      student.StudentCreatedAt,
      "MM/DD/YYYY HH:mm:ss"
    ).format("L");
    return moment(studentDate).isBetween(
      weekAgoDate,
      currentDate,
      undefined,
      "[]"
    );
  });
  const numberOfWeekAgoStudent = weekAgoStudent.length;

  // Calculate the date a month ago using moment
  const monthAgoDate = moment().subtract(1, "month").format("L");

  // Filter transactions for a month ago
  const monthAgoTransactions = data.filter((transaction) => {
    const transactionDate = moment(
      transaction.trans_date,
      "DD/MM/YYYY HH:mm:ss"
    ).format("L");
    return (
      transaction.order_status === "Success" &&
      moment(transactionDate).isBetween(
        monthAgoDate,
        currentDate,
        undefined,
        "[]"
      )
    );
  });
  const numberOfMonthAgoTransactions = monthAgoTransactions.length;
  const sumOfMonthAgoAmounts = monthAgoTransactions.reduce(
    (sum, transaction) => sum + parseInt(transaction.amount),
    0
  );

  const monthAgoStudent = studentData.filter((student) => {
    const studentDate = moment(
      student.StudentCreatedAt,
      "MM/DD/YYYY HH:mm:ss"
    ).format("L");
    return moment(studentDate).isBetween(
      monthAgoDate,
      currentDate,
      undefined,
      "[]"
    );
  });
  const numberOfMonthAgoStudent = monthAgoStudent.length;

  // Filter transactions for this year using moment
  const thisYearTransactions = data.filter((transaction) => {
    const transactionDate = moment(
      transaction.trans_date,
      "DD/MM/YYYY HH:mm:ss"
    ).format("L");
    return (
      transaction.order_status === "Success" &&
      moment(transactionDate).isSame(moment(), "year")
    );
  });
  const numberOfThisYearTransactions = thisYearTransactions.length;
  const sumOfThisYearAmounts = thisYearTransactions.reduce(
    (sum, transaction) => sum + parseInt(transaction.amount),
    0
  );

  // Filter Studnet for this year using moment
  const thisYearStudent = studentData.filter((student) => {
    const studentDate = moment(
      student.StudentCreatedAt,
      "MM/DD/YYYY HH:mm:ss"
    ).format("L");
    return moment(studentDate).isSame(moment(), "year");
  });
  const numberOfThisYearStudent = thisYearStudent.length;

  useEffect(() => {
    if (saleMenu === "Till Today") {
      setSaleMenuValue(OverAllSale);
    } else if (saleMenu === "Today") {
      setSaleMenuValue(numberOfTodayTransactions);
    } else if (saleMenu === "A Week Ago") {
      setSaleMenuValue(numberOfWeekAgoTransactions);
    } else if (saleMenu === "This Month") {
      setSaleMenuValue(numberOfMonthAgoTransactions);
    } else if (saleMenu === "This Year") {
      setSaleMenuValue(numberOfThisYearTransactions);
    }
  }, [saleMenu]);

  useEffect(() => {
    if (revenueMenu === "Till Today") {
      setRevenueMenuValue(OverAllRevenue);
    } else if (revenueMenu === "Today") {
      setRevenueMenuValue(sumOfTodayAmounts);
    } else if (revenueMenu === "A Week Ago") {
      setRevenueMenuValue(sumOfWeekAgoAmounts);
    } else if (revenueMenu === "This Month") {
      setRevenueMenuValue(sumOfMonthAgoAmounts);
    } else if (revenueMenu === "This Year") {
      setRevenueMenuValue(sumOfThisYearAmounts);
    }
  }, [revenueMenu]);

    useEffect(() => {
      if (customerMenu === "Till Today") {
        setCustomerMenuValue(OverAllStudent);
      } else if (customerMenu === "Today") {
        setCustomerMenuValue(numberOfTodayStudent);
      } else if (customerMenu === "A Week Ago") {
        setCustomerMenuValue(numberOfWeekAgoStudent);
      } else if (customerMenu === "This Month") {
        setCustomerMenuValue(numberOfMonthAgoStudent);
      } else if (customerMenu === "This Year") {
        setCustomerMenuValue(numberOfThisYearStudent);
      }
    }, [customerMenu]);


    // TopSelling items Calculation
    const topSelling199 = data.filter((transaction) => {
      return transaction.order_status === "Success" && transaction.amount === "199";
    });
    const topSelling299 = data.filter((transaction) => {
      return (
        transaction.order_status === "Success" && transaction.amount === "299"
      );
    });
    const topSelling499 = data.filter((transaction) => {
      return (
        transaction.order_status === "Success" && transaction.amount === "499"
      );
    });
    const topSelling999 = data.filter((transaction) => {
      return (
        transaction.order_status === "Success" && transaction.amount === "999"
      );
    });
    const numberOfTopSelling199 = topSelling199.length;
    const numberOfTopSelling299 = topSelling299.length;
    const numberOfTopSelling499 = topSelling499.length;
    const numberOfTopSelling999 = topSelling999.length;
    const sumOfTopSelling199 = topSelling199.reduce(
      (sum, transaction) => sum + parseInt(transaction.amount),
      0
    );
    const sumOfTopSelling299 = topSelling299.reduce(
      (sum, transaction) => sum + parseInt(transaction.amount),
      0
    );
    const sumOfTopSelling499 = topSelling499.reduce(
      (sum, transaction) => sum + parseInt(transaction.amount),
      0
    );
    const sumOfTopSelling999 = topSelling999.reduce(
      (sum, transaction) => sum + parseInt(transaction.amount),
      0
    );

    // Recent Student calculation
    const RecentStudent = studentData
      .slice(-5)
      .map((item) => {
        return {
          Name: item.Name,
          StudentCreatedAt: item.StudentCreatedAt,
        };
      })
    const RecentStudentReverse= RecentStudent.reverse();

    // RecentPayment Calculation
    const RecentPayment = successPaymentSale.slice(-5).map((item) => {
      return {
        Name: item.billing_name,
        amount: item.amount,
        trans_date: item.trans_date,
      };
    });
    const RecentPaymentReverse = RecentPayment.reverse();

  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-8">
            <div className="row">
              {/* Sales Card */}
              <div className="col-xxl-4 col-md-6">
                <div className="card info-card sales-card">
                  <div className="filter">
                    <Link
                      onClick={() => setIsOpenSale((prev) => !prev)}
                      className={`icon ${!isOpenSale && "show"}`}
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots" />
                    </Link>
                    <ul
                      className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${
                        !isOpenSale && "show"
                      }`}
                      style={{
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate3d(0px, 29.3333px, 0px)",
                      }}
                    >
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSaleMenu("Till Today");
                            setIsOpenSale((prev) => !prev);
                          }}
                        >
                          Till Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSaleMenu("Today");
                            setIsOpenSale((prev) => !prev);
                          }}
                        >
                          Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSaleMenu("A Week Ago");
                            setIsOpenSale((prev) => !prev);
                          }}
                        >
                          A Week Ago
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSaleMenu("This Month");
                            setIsOpenSale((prev) => !prev);
                          }}
                        >
                          This Month
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSaleMenu("This Year");
                            setIsOpenSale((prev) => !prev);
                          }}
                        >
                          This Year
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Sales <span>| {saleMenu}</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart" />
                      </div>
                      <div className="ps-3">
                        <h6>{saleMenuValue}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Sales Card */}
              {/* Revenue Card */}
              <div className="col-xxl-4 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="filter">
                    <Link
                      className={`icon ${!isOpenRevenue && "show"}`}
                      onClick={() => setIsOpenRevenue((prev) => !prev)}
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots" />
                    </Link>
                    <ul
                      className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${
                        !isOpenRevenue && "show"
                      }`}
                      style={{
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate3d(0px, 29.3333px, 0px)",
                      }}
                    >
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRevenueMenu("Till Today");
                            setIsOpenRevenue((prev) => !prev);
                          }}
                        >
                          Till Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRevenueMenu("Today");
                            setIsOpenRevenue((prev) => !prev);
                          }}
                        >
                          Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRevenueMenu("A Week Ago");
                            setIsOpenRevenue((prev) => !prev);
                          }}
                        >
                          A Week Ago
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRevenueMenu("This Month");
                            setIsOpenRevenue((prev) => !prev);
                          }}
                        >
                          This Month
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRevenueMenu("This Year");
                            setIsOpenRevenue((prev) => !prev);
                          }}
                        >
                          This Year
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Revenue <span>| {revenueMenu}</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-currency-rupee" />
                      </div>
                      <div className="ps-3">
                        <h6>{revenueMenuValue}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Revenue Card */}
              {/* Customers Card */}
              <div className="col-xxl-4 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="filter">
                    <Link
                      onClick={() => setIsOpenCustomer((prev) => !prev)}
                      className={`icon ${!isOpenCustomer && "show"}`}
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots" />
                    </Link>
                    <ul
                      className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${
                        !isOpenCustomer && "show"
                      }`}
                      style={{
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate3d(0px, 29.3333px, 0px)",
                      }}
                    >
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCustomerMenu("Till Today");
                            setIsOpenCustomer((prev) => !prev);
                          }}
                        >
                          Till Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCustomerMenu("Today");
                            setIsOpenCustomer((prev) => !prev);
                          }}
                        >
                          Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCustomerMenu("A Week Ago");
                            setIsOpenCustomer((prev) => !prev);
                          }}
                        >
                          A Week Ago
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCustomerMenu("This Month");
                            setIsOpenCustomer((prev) => !prev);
                          }}
                        >
                          This Month
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCustomerMenu("This Year");
                            setIsOpenCustomer((prev) => !prev);
                          }}
                        >
                          This Year
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      Customers <span>| {customerMenu}</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people" />
                      </div>
                      <div className="ps-3">
                        <h6>{customerMenuValue}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Customers Card */}
              {/* Top Selling */}
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <div className="card-body pb-0">
                    <h5 className="card-title">
                      Top Selling <span>| Till Today</span>
                    </h5>
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Service</th>
                          <th scope="col">Price</th>
                          <th scope="col">Sold</th>
                          <th scope="col">Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <Link>1</Link>
                          </th>
                          <td>
                            <Link className="text-primary fw-bold">
                              Marks Based Predictor 1 Mark 1 State
                            </Link>
                          </td>
                          <td>₹199</td>
                          <td className="fw-bold">{numberOfTopSelling199}</td>
                          <td>₹{sumOfTopSelling199}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Link>2</Link>
                          </th>
                          <td>
                            <Link className="text-primary fw-bold">
                              Marks Based Predictor 1 Mark 2 State
                            </Link>
                          </td>
                          <td>₹299</td>
                          <td className="fw-bold">{numberOfTopSelling299}</td>
                          <td>₹{sumOfTopSelling299}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Link>3</Link>
                          </th>
                          <td>
                            <Link className="text-primary fw-bold">
                              Marks Based Predictor 1 Mark All State
                            </Link>
                          </td>
                          <td>₹499</td>
                          <td className="fw-bold">{numberOfTopSelling499}</td>
                          <td>₹{sumOfTopSelling499}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Link>4</Link>
                          </th>
                          <td>
                            <Link className="text-primary fw-bold">
                              Rank Based MBBS College Predictor All State
                            </Link>
                          </td>
                          <td>₹999</td>
                          <td className="fw-bold">{numberOfTopSelling999}</td>
                          <td>₹{sumOfTopSelling999}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* End Top Selling */}
            </div>
          </div>
          {/* End Left side columns */}
          {/* Right side columns */}
          <div className="col-lg-4">
            {/* Recent Activity */}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Recent Student</h5>
                <div className="activity">
                  {/* End activity item*/}
                  {RecentStudentReverse.map((item) => (
                    <div className="activity-item d-flex">
                      <div className="activite-label">
                        {moment(
                          item.StudentCreatedAt,
                          "MM/DD/YYYY HH:mm:ss"
                        ).format("L")}
                      </div>
                      <i className="bi bi-circle-fill activity-badge text-danger align-self-start" />
                      <h6 className="activity-content">{item.Name}</h6>
                    </div>
                  ))}
                  {/* End activity item*/}
                </div>
              </div>
            </div>

            {/* Recent Activity */}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Recent Payment</h5>
                <div className="activity">
                  {/* End activity item*/}
                  {RecentPaymentReverse.map((item) => (
                    <div className="activity-item d-flex">
                      <div className="activite-label">
                        {moment(item.trans_date, "DD/MM/YYYY HH:mm:ss").format(
                          "L"
                        )}
                      </div>
                      <i className="bi bi-circle-fill activity-badge text-danger align-self-start" />
                      <h6 className="activity-content">{item.amount}</h6>
                      <h6 className="activity-content">{item.Name}</h6>
                    </div>
                  ))}
                  {/* End activity item*/}
                </div>
              </div>
            </div>
            {/* End Recent Activity */}
          </div>
          {/* End Right side columns */}
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
