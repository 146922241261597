import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteNeetCategory, getNeetCategoryList } from "../../services/neetCategory";
import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function NeetCategoryList() {
  const [data, setData] = useState([]);
  const fetchData = () => {
    getNeetCategoryList().then((result) => {
      if (result.data.error === false) {
        setData(result.data.result);
      }
    });
  };
  useEffect(() => fetchData(), []);

  const handleRemove = (id) => {
    deleteNeetCategory(id).then((result) => {
      if (result.data.error === false) {
        successMessage();
        fetchData();
      } else {
        errorMessage();
      }
    });
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">NEET Category List</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Title</th>
                      <th>Edit</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.category}</td>
                        <td>
                          <Link
                            to={"/neetCategory/edit"}
                            state={{ item }}
                            className="btn btn-warning"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        <td>
                          {item.id !== 6 && item.id !== 7 && (
                            <button
                              onClick={() => handleRemove(item.id)}
                              className="btn btn-danger"
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
