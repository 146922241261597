import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";
import httpClient from "../utils/httpClient";

export const createCollegeRank = ({
  collegeId,
  round,
  rank_min,
  rank_max,
  marks_min,
  marks_max,
  seat_count,
}) => {
  return axios({
    method: "post",
    url: BASE_URL + "college-rank",
    headers: getHeaders(),
    data: JSON.stringify({
      collegeId,
      round,
      rank_min,
      rank_max,
      marks_min,
      marks_max,
      seat_count,
    }),
  });
};

export const getCollegeRank = () => {
  return httpClient.get("college-rank");
};

export const findCollegeRank = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "college-rank/" + id,
    headers: getHeaders(),
  });
};


export const updateCollegeRank = (
  id,
  { collegeId, round, rank_min, rank_max, marks_min, marks_max, seat_count }
) => {
  return axios({
    method: "put",
    url: BASE_URL + "college-rank/" + id,
    headers: getHeaders(),
    data: JSON.stringify({
      collegeId,
      round,
      rank_min,
      rank_max,
      marks_min,
      marks_max,
      seat_count,
    }),
  });
};

export const deleteCollegeRank = (id) => httpClient.delete(`college-rank/${id}`);

export const createCollegeRanks = (data) =>
  httpClient.post("college-rank/bulk", JSON.stringify(data));