import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { errorMessage } from "../../utils/alertMessage";
import styled from "styled-components";
import { getOrder } from "../../services/payment";
import { getState } from "../../services/states";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "PaymentInfo.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <Button
    className="move-btn move-btn-re"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </Button>
);

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

// const TextField = styled.input`
//   height: 32px;
//   width: 200px;
//   border-radius: 3px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   border: 1px solid #e5e5e5;
//   padding: 0 32px 0 16px;

//   &:hover {
//     cursor: pointer;
//   }
// `;

// const ClearButton = styled(Button)`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const FilterComponent = ({ filterText, onFilter, onClear }) => (
//   <React.Fragment>
//     <TextField
//       id="search"
//       type="text"
//       placeholder="Search"
//       aria-label="Search Input"
//       value={filterText}
//       onChange={onFilter}
//     />
//     <ClearButton type="button" onClick={onClear}>
//       X
//     </ClearButton>
//   </React.Fragment>
// );

const PaymentInfo = () => {
  const [data, setData] = useState([{}]);
  const [stateList, setStateList] = useState([]);

  // const [filterText, setFilterText] = React.useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] =
  //   React.useState(false);
  // const filteredItems = data.filter(
  //   (item) =>
  //     (item.amount &&
  //       item.amount.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.order_id &&
  //       item.order_id.toLowerCase().includes(filterText.toLowerCase())) ||
  //     (item.billing_name &&
  //       item.billing_name.toLowerCase().includes(filterText.toLowerCase()))
  // );

  
  const newData = data.filter((c) => c.tracking_id !== null);
  const successPayment = newData.map((item) => {
    const param5 = item.merchant_param5;

    if (param5 != null) {
      const ids = param5.split(", ");

      const names = ids.map((id) => {
        const matchingItem = stateList.find(
          (item) => item.id.toString() === id.trim()
        );

        if (matchingItem) {
          return matchingItem.name;
        }

        return null;
      });

      item.merchant_param5 = names.join(", ");
    }

    return item;
  });

  // const subHeaderComponentMemo = React.useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <FilterComponent
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  const actionsMemo = useMemo(
    () => (
      <React.Fragment>
        <Export onExport={() => downloadCSV(successPayment)} />
      </React.Fragment>
    ),
    [successPayment]
  );

  async function getData() {
    getState()
      .then((response) => {
        if (response.data.error === false) {
          setStateList(response.data.result);
        }
      })
      .catch((err) => {
        errorMessage();
      });

    getOrder()
      .then((response) => {
        if (!response.data.error) {
          setData(
            response.data.result.map((item, index) => ({
              ID: index + 1,
              billing_name: item.billing_name,
              billing_tel: item.billing_tel,
              order_id: item.order_id,
              amount: item.amount,
              tracking_id: item.tracking_id,
              bank_ref_no: item.bank_ref_no,
              order_status: item.order_status,
              payment_mode: item.payment_mode,
              trans_date: item.trans_date,
              billing_address: item.billing_address,
              billing_city: item.billing_city,
              billing_state: item.billing_state,
              billing_zip: item.billing_zip,
              billing_email: item.billing_email,
              merchant_param3: item.merchant_param3,
              merchant_param4: item.merchant_param4,
              merchant_param5: item.merchant_param5,
            }))
          );
        }
      })
      .catch((err) => errorMessage(err))
      .finally(() => setPending(false));
  }

  useEffect(() => {
    getData();
  }, []);

  const [pending, setPending] = useState(true);
  const columns = useMemo(
    () => [
      { name: "#", sortable: true, selector: (row, index) => row.ID + 1 },
      {
        name: "Billing Name",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.billing_name} />,
        selector: (row) => row.billing_name,
      },
      {
        name: "Billing Number",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.billing_tel} />,
        selector: (row) => row.billing_tel,
      },
      {
        name: "Marks/ Rank",
        sortable: true,
        width: "130px",
        cell: (row) => <CustomTitle title={row.merchant_param4} />,
        selector: (row) => row.merchant_param4,
      },
      {
        name: "Selected State",
        sortable: true,
        width: "170px",
        cell: (row) => <CustomTitle title={row.merchant_param5} />,
        selector: (row) => row.merchant_param5,
      },
      {
        name: "Order Id",
        sortable: true,
        width: "180px",
        cell: (row) => <CustomTitle title={row.order_id} />,
        selector: (row) => row.order_id,
      },
      {
        name: "Amount",
        sortable: true,
        width: "100px",
        cell: (row) => <CustomTitle title={row.amount} />,
        selector: (row) => row.amount,
      },
      {
        name: "Tracking Id",
        sortable: true,
        width: "180px",
        cell: (row) => <CustomTitle title={row.tracking_id} />,
        selector: (row) => row.tracking_id,
      },
      {
        name: "Bank Ref No",
        sortable: true,
        width: "200px",
        cell: (row) => <CustomTitle title={row.bank_ref_no} />,
        selector: (row) => row.bank_ref_no,
      },
      {
        name: "Order Status",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.order_status} />,
        selector: (row) => row.order_status,
      },
      {
        name: "Payment Mode",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.payment_mode} />,
        selector: (row) => row.payment_mode,
      },
      {
        name: "Trans Time",
        sortable: true,
        width: "180px",
        cell: (row) => <CustomTitle title={row.trans_date} />,
        selector: (row) => row.trans_date,
      },
      {
        name: "Billing Address",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.billing_address} />,
        selector: (row) => row.billing_address,
      },
      {
        name: "Billing City",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.billing_city} />,
        selector: (row) => row.billing_city,
      },
      {
        name: "Billing State",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.billing_state} />,
        selector: (row) => row.billing_state,
      },
      {
        name: "Billing Zip",
        sortable: true,
        width: "120px",
        cell: (row) => <CustomTitle title={row.billing_zip} />,
        selector: (row) => row.billing_zip,
      },
      {
        name: "Billing Email",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.billing_email} />,
        selector: (row) => row.billing_email,
      },
    ],
    []
  );

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Payment Information</h5>
                <DataTable
                  columns={columns}
                  data={successPayment}
                  progressPending={pending}
                  actions={actionsMemo}
                  pagination
                  // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PaymentInfo;
