import axios from "axios";
import { BASE_URL } from "../constants";
import  getHeaders from "../constants/headers";

export const getState = () => {
  return axios({
    method: "get",
    url: BASE_URL + "state",
    headers:  getHeaders(),
  });
};
