import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const MenuLink = ({ subMenu, index }) => {
  return (
    <li key={index}>
      <Link to={subMenu.url}>
        <i className="bi bi-circle" />
        <span>{subMenu.title}</span>
      </Link>
    </li>
  )
}

export default function SubMenu({ item, index }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className="nav-item" key={index}>
      <a
        onClick={() => setIsOpen((prev) => !prev)}
        className={`nav-link ${!isOpen && "collapsed"}`}
        data-bs-target="#components-nav"
        data-bs-toggle="collapse"
        style={{ cursor: "pointer" }}
      >
        <i className={item.icon} />
        <span>{item.title}</span>
        {item.child && <i className="bi bi-chevron-down ms-auto" />}
      </a>
      {item.child && (
        <ul
          id="components-nav"
          className={`nav-content ${!isOpen && "collapse"}`}
          data-bs-parent="#sidebar-nav"
        >
          {item.child.map((subMenu, index) => (
            <MenuLink key={index} subMenu={subMenu} index={index} />
          ))}
        </ul>
      )}
    </li>
  );
}
