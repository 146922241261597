import httpClient from "../utils/httpClient";

export const getInstitutionTypeList = () => httpClient.get('institution-type');

export const createInstitutionType = (data) => httpClient.post('institution-type', JSON.stringify(data));

export const updateInstitutionType = (data) => httpClient.put(`institution-type/${data.id}`, JSON.stringify(data));

export const deleteInstitutionType = (id) => httpClient.delete(`institution-type/${id}`);

