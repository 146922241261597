import httpClient from "../utils/httpClient";

export const getSeatTypeList = () => httpClient.get('seat-type');

export const createSeatType = (data) => httpClient.post('seat-type', JSON.stringify(data));

export const updateSeatType = (data) => httpClient.put(`seat-type/${data.id}`, JSON.stringify(data));

export const deleteSeatType = (id) => httpClient.delete(`seat-type/${id}`);

