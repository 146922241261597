import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getCollege } from "../../services/colleges";
import {
  createCollegeRank,
  getCollegeRank,
  updateCollegeRank,
} from "../../services/collegesRank";
import {
  dataLoadingMessage,
  errorMessage,
  successMessage,
} from "../../utils/alertMessage";

const CollegeRank = () => {
  const [data, setData] = useState([]);
  const [availableCollegeOtherData, setAvailableCollegeOtherData] = useState(
    []
  );
  const [rankData, setRankData] = useState([]);
  const [roundData, setRoundData] = useState([]);
  const { state } = useLocation();

  const [input, setInput] = useState({
    collegeId: state?.collegeId || "",
    round: state?.round || "",
    rank_min: state?.rank_min || "",
    rank_max: state?.rank_max || "",
    marks_min: state?.marks_min || "",
    marks_max: state?.marks_max || "",
    seat_count: state?.seat_count || "",
  });
  const navigate = useNavigate();

  const roundDataOld = [1, 2, 3, 4, 5, 6, 7, 8];

  useEffect(() => {
    async function getData() {
      dataLoadingMessage();
      getCollege()
        .then((response) => {
          if (!response.data.error) {
            setData(
              response.data.result.map((item) => ({
                ...item,
              }))
            );
            Swal.close();
          }
        })
        .catch((err) => {
          errorMessage();
        });

      getCollegeRank()
        .then((response) => {
          if (!response.data.error) {
            setRankData(
              response.data.result.map((item) => ({
                ...item,
              }))
            );
          }
        })
        .catch((err) => errorMessage());
    }
    getData();
  }, []);

  useEffect(() => {
    const handleCollegeAllData = (id) => {
      const allData = data.filter((s) => s.id == id);
      // const result = Object.assign({}, ...allData);
      setAvailableCollegeOtherData(allData);
    };
    handleCollegeAllData(input.collegeId);
  }, [data, input.collegeId]);

  const handleCollegeRank = (id) => {
    if (rankData) {
      const availableCollege = rankData.filter((s) => s.collegeId == id);
      const availableRound = availableCollege.map((item) => ({
        round: item.round,
      }));

      var roundValue = availableRound.map(function (item) {
        return item["round"];
      });
      setRoundData(roundDataOld.filter((x) => !roundValue.includes(x)));
    }
  };

  const handleCollegeRankData = async (event) => {
    event.preventDefault();
    if (state) {
      updateCollegeRank(state.collegeId, {
        ...input,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/collegeRank/list");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    } else {
      createCollegeRank(input)
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/collegeRank/list");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  const counsellingSeat =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].counselling_seat === null
        ? "N/A"
        : availableCollegeOtherData[0].counselling_seat.counsellingSeat
      : "";
  const eligibility =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].eligibility === null
        ? "N/A"
        : availableCollegeOtherData[0].eligibility.eligibility
      : "";
  const seatType =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].seat_type === null
        ? "N/A"
        : availableCollegeOtherData[0].seat_type.seatType
      : "";
  const neetCategory =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].neet_category === null
        ? "No"
        : availableCollegeOtherData[0].neet_category.category
      : "";

  const stateCategory =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].state_category === null
        ? "No"
        : availableCollegeOtherData[0].state_category.category
      : "";

  const minority =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].minority === null
        ? "No"
        : availableCollegeOtherData[0].minority.minority
      : "";
  const armedForce =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].armed_force === null
        ? "No"
        : availableCollegeOtherData[0].armed_force.armedForce
      : "";
  const quota =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].quota === null
        ? "No"
        : availableCollegeOtherData[0].quota.quota
      : "";
  const isPhCandidate =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].isPhCandidate === true
        ? "Yes"
        : "No"
      : "";
  const area =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].area === null
        ? "N/A"
        : availableCollegeOtherData[0].area.area
      : "";
  const isfemaleSeat =
    availableCollegeOtherData.length !== 0
      ? availableCollegeOtherData[0].isFemaleSeat === true
        ? "Female"
        : "General"
      : "";

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  College Rank {state ? "Edit" : "Create"}
                </h5>
                <form className="g-3" onSubmit={handleCollegeRankData}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="collegeId" className="form-label">
                        College
                      </label>
                      <select
                        id="collegeId"
                        name="collegeId"
                        value={input.collegeId}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            collegeId: e.target.value,
                          });
                          handleCollegeRank(e.target.value);
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {state ? (
                          <option value={input.collegeId}>
                            College Id- {input.collegeId}, {state.CollegeName}
                          </option>
                        ) : (
                          data.map((item) => (
                            <option key={item.id} value={item.id}>
                              Id- {item.id}, {item.name}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="counselling_seat" className="form-label">
                        Counselling Seat
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="counselling_seat"
                        name="counselling_seat"
                        value={counsellingSeat}
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="eligibility" className="form-label">
                        Eligibility
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="eligibility"
                        name="eligibility"
                        value={eligibility}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="seat_type" className="form-label">
                        Seat Type
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="seat_type"
                        name="seat_type"
                        value={seatType}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Area" className="form-label">
                        Area
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="area"
                        name="area"
                        value={area}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="neet_category" className="form-label">
                        NEET Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="neet_category"
                        name="neet_category"
                        value={neetCategory}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="state_category" className="form-label">
                        State Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state_category"
                        name="state_category"
                        value={stateCategory}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="minority" className="form-label">
                        Minority
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="minority"
                        name="minority"
                        value={minority}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="armed_force" className="form-label">
                        Armed Force
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="armed_force"
                        name="armed_force"
                        value={armedForce}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="quota" className="form-label">
                        Quota
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="quota"
                        name="quota"
                        value={quota}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="isFemaleSeat" className="form-label">
                        Is Female Seat
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="isFemaleSeat"
                        name="isFemaleSeat"
                        value={isfemaleSeat}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="isPhCandidate" className="form-label">
                        isPhCandidate
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="isPhCandidate"
                        name="isPhCandidate"
                        value={isPhCandidate}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="round" className="form-label">
                        Round
                      </label>
                      <select
                        id="round"
                        name="round"
                        value={input.round}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            round: e.target.value,
                          });
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select Option</option>
                        {state && (
                          <option value={input.round}>{input.round}</option>
                        )}

                        {roundData.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="seat_count" className="form-label">
                        Seat Count
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        id="seat_count"
                        name="seat_count"
                        value={input.seat_count}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            seat_count: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="rank_min" className="form-label">
                        Minimum Rank
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        id="rank_min"
                        name="rank_min"
                        value={input.rank_min}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            rank_min: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="rank_max" className="form-label">
                        Maximum Rank
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        id="rank_max"
                        name="rank_max"
                        value={input.rank_max}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            rank_max: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="marks_min" className="form-label">
                        Minimum Marks
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="marks_min"
                        min={0}
                        name="marks_min"
                        value={input.marks_min}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            marks_min: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="marks_max" className="form-label">
                        Maximum Marks
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="marks_max"
                        min={0}
                        name="marks_max"
                        value={input.marks_max}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            marks_max: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CollegeRank;
