import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createRankPredictor,
  updateRankPredictor,
} from "../../services/rankPredictor";
import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function RankPredictor() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [input, setInput] = useState({
    year: state?.year || new Date().getFullYear(),
    score: state?.marks || "",
    minNeetRank: state?.minRank || "",
    maxNeetRank: state?.maxRank || "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state) {
      updateRankPredictor({
        id: state?.id,
        marks: input.score,
        minRank: input.minNeetRank,
        maxRank: input.maxNeetRank,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/rankPredictor/list");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    } else {
      createRankPredictor({
        marks: input.score,
        minRank: input.minNeetRank,
        maxRank: input.maxNeetRank,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/rankPredictor/list");
          } else {
            errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Score Rank Ranges</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="score" className="form-label">
                        Year
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="year"
                        name="year"
                        value={input.year}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            year: e.target.value,
                          });
                        }}
                        readOnly
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="score" className="form-label">
                        Score
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="score"
                        name="score"
                        min={90}
                        max={720}
                        value={input.score}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            score: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="minNeetRank" className="form-label">
                        Minimum Rank Range
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="minNeetRank"
                        name="minNeetRank"
                        min={1}
                        max={10000000}
                        value={input.minNeetRank}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            minNeetRank: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="maxNeetRank" className="form-label">
                        Maximum Rank Range
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="maxNeetRank"
                        name="maxNeetRank"
                        min={1}
                        max={10000000}
                        value={input.maxNeetRank}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            maxNeetRank: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
