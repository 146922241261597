import httpClient from "../utils/httpClient";

export const getFeeRangeList = () => httpClient.get('fee-range');

export const createFeeRange = (data) => httpClient.post('fee-range', JSON.stringify(data));

export const updateFeeRange = (data) => httpClient.put(`fee-range/${data.id}`, JSON.stringify(data));

export const deleteFeeRange = (id) => httpClient.delete(`fee-range/${id}`);

