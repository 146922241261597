import httpClient from "../utils/httpClient";

export const getRankPredictorList = (year = null) => {
  const queryString = year ? `?year=${year}` : "";
  return httpClient.get("rank" + queryString);};

export const createRankPredictor = (data) =>
  httpClient.post("rank", JSON.stringify(data));

export const updateRankPredictor = (data) =>
  httpClient.put(`rank/${data.id}`, JSON.stringify(data));

export const deleteRankPredictor = (id) =>
  httpClient.delete(
    `rank/${id}`
  );

