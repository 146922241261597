import httpClient from "../utils/httpClient";

export const getAreaList = () => httpClient.get('area');

export const createArea = (data) => httpClient.post('area', JSON.stringify(data));

export const updateArea = (data) => httpClient.put(`area/${data.id}`, JSON.stringify(data));

export const deleteArea = (id) => httpClient.delete(`area/${id}`);

