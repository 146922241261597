import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getInitData = () => {
  return axios({
    method: "get",
    url: BASE_URL + "init-data",
    headers: getHeaders(),
  });
};
