import httpClient from "../utils/httpClient";

export const getMinorityList = () => httpClient.get('minority');

export const createMinority = (data) => httpClient.post('minority', JSON.stringify(data));

export const updateMinority = (data) => httpClient.put(`minority/${data.id}`, JSON.stringify(data));

export const deleteMinority = (id) => httpClient.delete(`minority/${id}`);

