import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";

const AuthLayout = () => {
    const accessToken = useSelector((state) => state.auth.accessToken);
    return accessToken ? <Navigate to='/' replace /> : <Outlet />;
};

export default AuthLayout;
