import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getNeetCategoryList } from "../../services/neetCategory";
import { createNeetCategoryCutoff, updateNeetCategoryCutoff } from "../../services/neetCategoryCutoff";

export default function NeetCategoryCutoff() {
  const navigate = useNavigate();
  const { state } = useLocation();
  
  const cutoffValue = state?.item
  ? { selectedCategory: state.item.cutoff }
  : { selectedCategory: "" };
  // const categoryValue = state?.item
  // ? { neet_category: state.item.neetCategoryId }
  // : { neet_category: "" };

  const [neetCategoryList, setNeetCategoryList] = useState([]);
  
  const [selectedState, setSelectedState] = useState({
    neetCategoryId: state?.item.neetCategoryId || "",
  });
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    async function getData() {
      getNeetCategoryList()
        .then((response) => {
          if (response.data.error === false) {
            setNeetCategoryList(response.data.result);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      cutoff: selectedCategory,
      neetCategoryId: state?.item ? selectedState.neetCategoryId : selectedState,
    };
    if (state?.item) {
      updateNeetCategoryCutoff({
        ...payload,
        id: state?.item.id,
      })
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/neetCategoryCutoff/list");
          } else {
            if (
              response.data.errors.errors[0].message ===
              "neetCategoryId must be unique"
            ) {
              errorMessage("Selected Data is already present");
            } else {
              errorMessage();
            }
          }
        })
        .catch((err) => {
          errorMessage();
        });
    } else {
      createNeetCategoryCutoff(payload)
        .then((response) => {
          if (response.data.error === false) {
            successMessage();
            navigate("/neetCategoryCutoff/list");
          } else {
            if (
              response.data.errors.errors[0].message ===
              "neetCategoryId must be unique"
            ) {
              errorMessage("Selected Data is already present");
            } else {
              errorMessage();
            }
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">NEET Category Cutoff</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="neetCategoryId" className="form-label">
                        NEET Category
                      </label>
                      <select
                        id="neetCategoryId"
                        name="neetCategoryId"
                        className="form-select"
                        onChange={(e) => setSelectedState(e.target.value)}
                        required
                        value={selectedState.neetCategoryId}
                      >
                        <option>Please select</option>
                        {neetCategoryList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.category}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="cutoff" className="form-label">
                        NEET Category Cutoff Marks
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="cutoff"
                        name="cutoff"
                        min={1}
                        max={720}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        defaultValue={cutoffValue.selectedCategory}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
