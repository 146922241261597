export const setUserData = ({ token, userId }) => {
  localStorage.setItem("adminToken", token);
  localStorage.setItem("userId", userId);
};

export const getUserData = () => {
  const token = localStorage.getItem("adminToken");
  const userId = localStorage.getItem("userId");
  return { token, userId };
}

export const removeUserData = () => {
  localStorage.removeItem("adminToken");
  localStorage.removeItem("userId");
};