import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleSidebar } from '../redux/authSlice';
// import logo from "../assets/logo.png";

const Header = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const handleToggleSidebar = () => dispatch(toggleSidebar());
    return (
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link to={"/"} className="logo d-flex align-items-center">
            <img
              src="/image/blue-logo-final 1.png"
              alt="NEET Navigator"
              style={{ maxHeight: "100px" }}
            />
            {/*<span className="d-none d-lg-block">NEET Navigator</span>*/}
          </Link>
          <i
            onClick={handleToggleSidebar}
            className="bi bi-list toggle-sidebar-btn"
          ></i>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <button
                onClick={() => setIsOpen((prev) => !prev)}
                className={`btn btn-link nav-link nav-profile d-flex align-items-center pe-0 ${
                  !isOpen && "show"
                }`}
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  Account
                </span>
                <span className="logout-btn">
                  <i
                    className={"bi bi-power icon-300"}
                    style={{fontSize:25}}
                  />
                </span>
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${
                  !isOpen && "show"
                }`}
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  margin: "0px",
                  transform: "translate3d(-16.6667px, 38px, 0px)",
                }}
              >
                <li className="dropdown-header">
                  <h6>NEET Navigator</h6>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to="/logout"
                  >
                    <i className="bi bi-box-arrow-right" />
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    );
}
export default Header;