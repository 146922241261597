import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Root from '../authLayout/Root';
import Area from '../pages/area/Area';
import Quota from "../pages/quota/Quota";
import Course from '../pages/course/Course';
import Region from '../pages/region/Region';
import AreaList from '../pages/area/AreaList';
import QuotaList from "../pages/quota/QuotaList";
import AuthLayout from '../authLayout/AuthLayout';
import SeatType from '../pages/seatType/seatType';
import FeeRange from '../pages/feeRange/FeeRange';
import Minority from '../pages/minority/Minority';
import RegionList from '../pages/region/RegionList';
import CourseList from '../pages/course/CourseList';
import CollegeList from "../pages/college/CollegeList";
import StudentList from '../pages/student/StudentList';
import ArmedForce from '../pages/armedForce/ArmedForce';
import SeatTypeList from '../pages/seatType/seatTypeList';
import FeeRangeList from '../pages/feeRange/FeeRangeList';
import Authentication from '../authLayout/Authentication';
import MinorityList from '../pages/minority/MinorityList';
import CollegeRank from '../pages/collegeRank/CollegeRank';
import CollegeCreate from "../pages/college/CollegeCreate";
import AreaOfState from '../pages/areaOfState/AreaOfState';
import Eligibility from '../pages/eligibility/Eligibility';
import NeetCategory from '../pages/neetCategory/NeetCategory';
import CollegeListData from "../pages/college/CollegeListData";
import ArmedForceList from '../pages/armedForce/ArmedForceList';
import StateCategory from '../pages/stateCategory/StateCategory';
import EligibilityList from '../pages/eligibility/EligibilityList';
import AreaOfStateList from '../pages/areaOfState/AreaOfStateList';
import CollegeRankList from '../pages/collegeRank/CollegeRankList';
import NeetCategoryList from '../pages/neetCategory/NeetCategoryList';
import InstitutionType from '../pages/institutionType/InstitutionType';
import CounsellingSeat from '../pages/counsellingSeat/CounsellingSeat';
import StateCategoryList from '../pages/stateCategory/StateCategoryList';
import InstitutionTypeList from '../pages/institutionType/InstitutionTypeList';
import NeetCategoryCutoff from '../pages/neetCategoryCutoff/NeetCategoryCutoff';
import CounsellingSeatList from '../pages/counsellingSeat/CounsellingSeatList';
import StateCategoryOfState from "../pages/stateCategoryOfState/StateCategoryOfState";
import NeetCategoryCutoffList from "../pages/neetCategoryCutoff/NeetCategoryCutoffList";
import StateCategoryOfStateList from "../pages/stateCategoryOfState/StateCategoryOfStateList";
import RankPredictorList from '../pages/rankPredictor/RankPredictorList';
import RankPredictor from '../pages/rankPredictor/RankPredictor';
import PaymentInfo from '../pages/payment/PaymentInfo';
import Dashboard from '../pages/Dashboard';
import UserLeads from '../pages/leads/UserLeads';
import UserEnquiries from '../pages/enquiries/UserEnquiries';
import PublishReport from '../pages/student/PublishReport';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="area/list" element={<AreaList />} />
        <Route path="area/create" element={<Area />} />
        <Route path="area/edit" element={<Area />} />
        <Route path="areaOfState/list" element={<AreaOfStateList />} />
        <Route path="areaOfState/create" element={<AreaOfState />} />
        <Route path="areaOfState/edit" element={<AreaOfState />} />
        <Route
          path="stateCategoryOfState/list"
          element={<StateCategoryOfStateList />}
        />
        <Route
          path="stateCategoryOfState/create"
          element={<StateCategoryOfState />}
        />
        <Route
          path="stateCategoryOfState/edit"
          element={<StateCategoryOfState />}
        />
        <Route path="armedForce/list" element={<ArmedForceList />} />
        <Route path="armedForce/create" element={<ArmedForce />} />
        <Route path="armedForce/edit" element={<ArmedForce />} />
        <Route path="quota/list" element={<QuotaList />} />
        <Route path="quota/create" element={<Quota />} />
        <Route path="quota/edit" element={<Quota />} />
        <Route path="minority/list" element={<MinorityList />} />
        <Route path="minority/create" element={<Minority />} />
        <Route path="minority/edit" element={<Minority />} />
        <Route path="course/list" element={<CourseList />} />
        <Route path="course/create" element={<Course />} />
        <Route path="course/edit" element={<Course />} />
        <Route path="eligibility/list" element={<EligibilityList />} />
        <Route path="eligibility/create" element={<Eligibility />} />
        <Route path="eligibility/edit" element={<Eligibility />} />
        <Route path="institutionType/list" element={<InstitutionTypeList />} />
        <Route path="institutionType/create" element={<InstitutionType />} />
        <Route path="institutionType/edit" element={<InstitutionType />} />
        <Route path="seatType/list" element={<SeatTypeList />} />
        <Route path="seatType/create" element={<SeatType />} />
        <Route path="seatType/edit" element={<SeatType />} />
        <Route path="feeRange/list" element={<FeeRangeList />} />
        <Route path="feeRange/create" element={<FeeRange />} />
        <Route path="feeRange/edit" element={<FeeRange />} />
        <Route path="counsellingSeat/list" element={<CounsellingSeatList />} />
        <Route path="counsellingSeat/create" element={<CounsellingSeat />} />
        <Route path="counsellingSeat/edit" element={<CounsellingSeat />} />
        <Route path="neetCategory/list" element={<NeetCategoryList />} />
        <Route path="neetCategory/create" element={<NeetCategory />} />
        <Route path="neetCategory/edit" element={<NeetCategory />} />
        <Route path="stateCategory/list" element={<StateCategoryList />} />
        <Route path="stateCategory/create" element={<StateCategory />} />
        <Route path="stateCategory/edit" element={<StateCategory />} />
        <Route path="region/list" element={<RegionList />} />
        <Route path="region/create" element={<Region />} />
        <Route path="region/edit" element={<Region />} />
        <Route
          path="neetCategoryCutoff/list"
          element={<NeetCategoryCutoffList />}
        />
        <Route
          path="neetCategoryCutoff/create"
          element={<NeetCategoryCutoff />}
        />
        <Route
          path="neetCategoryCutoff/edit"
          element={<NeetCategoryCutoff />}
        />
        <Route path="rankPredictor/list" element={<RankPredictorList />} />
        <Route path="rankPredictor/create" element={<RankPredictor />} />
        <Route path="rankPredictor/edit" element={<RankPredictor />} />

        <Route path="college" element={<CollegeCreate />} />
        <Route path="colleges" element={<CollegeList />} />
        <Route path="college/view" element={<CollegeListData />} />
        <Route path="collegeRank" element={<CollegeRank />} />
        <Route path="collegeRank/list" element={<CollegeRankList />} />
        <Route path="student/list" element={<StudentList />} />
        <Route path="publishreport" element={<PublishReport/>}/>
        <Route path="payment-info" element={<PaymentInfo />} />
        <Route path="user-leads" element={<UserLeads />} />
        <Route path="user-enquiries" element={<UserEnquiries />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;