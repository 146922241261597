import React from 'react';
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import Footer from "../layout/Footer";
import SideBar from "../layout/SideBar";
import Header from "../layout/Header";

const MainLayout = () => {
    const { isSidebarOpen } = useSelector((state) => state.auth);
    return (
        <div className={isSidebarOpen ? "" : "toggle-sidebar"}>
            <Header />
            <SideBar />
            <Outlet />
            <Footer />
        </div>
    )
};

export default MainLayout;
