import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { getState } from "../../services/states";
import { getStateCategoryList } from "../../services/stateCategory";
import { createStateCategoryOfState } from "../../services/stateCategoryOfState";
import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function StateCategoryOfState() {
  const navigate = useNavigate();

  const [stateList, setStateList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    async function getData() {
      getState()
        .then((response) => {
          if (response.data.error === false) {
            setStateList(response.data.result);
          }
        })
        .catch((err) => {
          errorMessage();
        });

      getStateCategoryList()
        .then((response) => {
          if (response.data.error === false) {
            setCategoryList(
              response.data.result.map((category) => ({
                value: category.id,
                label: category.category,
              }))
            );
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    }
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = selectedCategory.map((stateCategory) => ({
      stateCategoryId: stateCategory.value,
      stateId: selectedState,
    }));
    createStateCategoryOfState(payload)
      .then((response) => {
        if (response.data.error === false) {
          successMessage();
          navigate("/stateCategoryOfState/list");
        } else {
          if (
            response.data.errors.errors[0].message === "PRIMARY must be unique"
          ) {
            errorMessage("Selected Data is already present");
          } else {
            errorMessage();
          }
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const handleSelect = (data) => setSelectedCategory(data);

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">State Category Of State</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="stateId" className="form-label">
                        State
                      </label>
                      <select
                        id="stateId"
                        name="stateId"
                        className="form-select"
                        onChange={(e) => setSelectedState(e.target.value)}
                        required
                      >
                        <option>Please select</option>
                        {stateList.map((states) => (
                          <option key={states.id} value={states.id}>
                            id {states.id} , {states.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="categoryId" className="form-label">
                        State Category Of State
                      </label>
                      <Select
                        options={categoryList}
                        value={selectedCategory}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
