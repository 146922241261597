import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import {
  createCollegeRanks,
  deleteCollegeRank,
  getCollegeRank,
} from "../../services/collegesRank";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import styled from "styled-components";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

function parseCSV(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}

function importCSV(getData) {
  Swal.fire({
    title: "Import CSV File",
    html: '<a href="/assets/college_rank_import.csv" download="college-rank-sample.csv">College Rank Sample File</a>',
    input: "file",
    inputAttributes: {
      autocapitalize: "off",
      accept: ".csv",
      required: true,
    },
    showCancelButton: true,
    confirmButtonText: "Import",
    showLoaderOnConfirm: true,
    preConfirm: (value) => {
      if (value) {
        const reader = new FileReader();
        reader.readAsText(value);
        return (reader.onload = () => {
          const data = reader.result;
          Swal.fire({
            title: "Please Wait...",
            didOpen: () => {
              Swal.showLoading();
            },
          });
          return parseCSV(data).then((inputFileData) => {
            const collegeRankData = inputFileData.filter(
              (item) => !!item.collegeId
            );
            return createCollegeRanks(collegeRankData)
              .then((result) => {
                if (result.data.error === false) {
                  successMessage();
                  getData();
                } else {
                  errorMessage();
                }
              })
              .catch((err) => errorMessage(err));
          });
        });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
  });
}

const Export = ({ onExport }) => (
  <Button
    className="move-btn move-btn-re"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </Button>
);

const Import = ({ onImport }) => (
  <Button
    className="move-btn move-btn-su"
    onClick={(e) => onImport(e.target.value)}
  >
    Import
  </Button>
);

const AddCollegeRank = () => (
  <Link className=" btn btn-primary move-btn move-btn-su" to="/collegeRank">
    Create
  </Link>
);

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Search College"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

const CollegeRankList = () => {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const columns = useMemo(
    () => [
      {
        name: "College Id",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.collegeId} />,
        selector: (row) => row.collegeId,
      },
      {
        name: "College Name",
        sortable: true,
        width: "250px",
        cell: (row) => <CustomTitle title={row.CollegeName} />,
        selector: (row) => row.CollegeName,
      },
      {
        name: "round",
        sortable: true,
        selector: (row) => row.round,
      },
      {
        name: "Minimum Rank",
        sortable: true,
        width: "150px",
        selector: (row) => row.rank_min,
      },
      {
        name: "Maximum Rank",
        sortable: true,
        width: "150px",
        selector: (row) => row.rank_max,
      },
      {
        name: "Minimum Marks",
        sortable: true,
        width: "150px",
        selector: (row) => row.marks_min,
      },
      {
        name: "Maximum Marks",
        sortable: true,
        width: "150px",
        selector: (row) => row.marks_max,
      },
      {
        name: "Seat Count",
        sortable: true,
        width: "150px",
        selector: (row) => row.seat_count,
      },

      {
        name: "Edit",
        selector: (row) => (
          <Link
            type="button"
            to={"/collegeRank"}
            state={row}
            className="btn btn-warning"
          >
            <i className="bi bi-pencil-square"></i>
          </Link>
        ),
      },
      {
        name: "Delete",
        selector: (row) => (
          <Link
            type="button"
            onClick={() => handleRemove(row.id)}
            state={row}
            className="btn btn-danger"
          >
            <i className="bi bi-trash"></i>
          </Link>
        ),
      },
    ],
    []
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.CollegeName &&
      item.CollegeName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const actionsMemo = useMemo(
    () => (
      <React.Fragment>
        <Import onImport={() => importCSV(getData)} />
        <Export onExport={() => downloadCSV(data)} />
        <AddCollegeRank />
      </React.Fragment>
    ),
    [data]
  );

  async function getData() {
    getCollegeRank()
      .then((response) => {
        if (!response.data.error) {
          setData(
            response.data.result.map((item) => ({
              id: item.id,
              collegeId: item.collegeId,
              round: item.round,
              seat_count: item.seat_count,
              rank_min: item.rank_min,
              rank_max: item.rank_max,
              marks_min: item.marks_min,
              marks_max: item.marks_max,
              CollegeName: item.college.name,
              // ...item,
            }))
          );
        }
      })
      .catch((err) => errorMessage(err))
      .finally(() => setPending(false));
  }
  const handleRemove = (id) => {
    deleteCollegeRank(id).then((result) => {
      if (result.data.error === false) {
        successMessage();
        getData();
      } else {
        errorMessage();
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">College Rank List</h5>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  progressPending={pending}
                  pagination
                  actions={actionsMemo}
                  highlightOnHover
                  striped
                  columnFilter
                  columnSorter
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CollegeRankList;
