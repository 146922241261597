import menu from '../constants/menu.json';
import SubMenu from "../components/SubMenu";
import { Link } from 'react-router-dom';

const SideBar = () => (
  <aside id="sidebar" className="sidebar">
    <ul className="sidebar-nav" id="sidebar-nav">
      {menu.map((item, index) => (
        <SubMenu key={index} item={item} index={index} />
      ))}
      {/* <li className="nav-item" sx={{ color: "#012970" }}>
        <Link className={`nav-link collapsed`} to={"student/list"}>
          <i className={"bi bi-person-vcard"} />
          <span>{"Student"}</span>
        </Link>
      </li> */}
      <li className="nav-item" sx={{ color: "#012970" }}>
        <Link className={`nav-link collapsed`} to={"payment-info"}>
          <i className={"bi bi-wallet2"} />
          <span>{"Payment Info"}</span>
        </Link>
      </li>
      <li className="nav-item" sx={{ color: "#012970" }}>
        <Link className={`nav-link collapsed`} to={"user-leads"}>
          <i className={"bi bi-person-vcard"} />
          <span>{"User Leads"}</span>
        </Link>
      </li>
      <li className="nav-item" sx={{ color: "#012970" }}>
        <Link className={`nav-link collapsed`} to={"user-enquiries"}>
          <i className={"bi bi-person-vcard"} />
          <span>{"User Enquiries"}</span>
        </Link>
      </li>
    </ul>
  </aside>
);
export default SideBar;